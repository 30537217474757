import { gql, useLazyQuery } from '@apollo/client';
import React, { createContext, useEffect, useReducer } from 'react';

type OrgSettingsAction = {
  type: 'UPDATE_SETTINGS';
  settings: OrgSettings;
}

export type OrgSettings = {
  orgId: string;
  hideEndTimeByDefault: boolean;
  requireApprovalForOpenShiftPickups: boolean;
  showStaffContactInfo: boolean;
  startDayOfWeek: string;
}

type OrgSettingsContextProps = {
  settings: OrgSettings;
  updateSettings: (settings: OrgSettings) => void;
}

type OrgSettingsProviderProps = {
  children: React.ReactNode;
}

export const OrgSettingsContext = createContext<OrgSettingsContextProps>({} as OrgSettingsContextProps);

export const GET_SCHEDULE_SETTINGS = gql`
query getOrgScheduleSettingsForState {
  orgScheduleSettings {
    orgId
    hideEndTimeByDefault
    requireApprovalForOpenShiftPickups
    startDayOfWeek
    showStaffContactInfo
  }
}
`;

export type GetScheduleSettingsResponse = {
  orgScheduleSettings: OrgSettings;
}

const settingsReducer = (state: OrgSettings, action: OrgSettingsAction) => {
  switch (action.type) {
    case 'UPDATE_SETTINGS': {
      return {
        ...state,
        ...action.settings,
      }
    }
    default: {
      return state;
    }
  }
}

const defaultOrgSettings: OrgSettings = {
  orgId: '',
  hideEndTimeByDefault: false,
  requireApprovalForOpenShiftPickups: false,
  showStaffContactInfo: false,
  startDayOfWeek: 'MONDAY',
}

export default function OrgSettingsProvider({ children }: OrgSettingsProviderProps) {
  const [settings, dispatch] = useReducer(settingsReducer, defaultOrgSettings);
  const [getScheduleSettings] = useLazyQuery<GetScheduleSettingsResponse>(GET_SCHEDULE_SETTINGS, {
    onCompleted: (data) => {
      dispatch({
        type: 'UPDATE_SETTINGS',
        settings: data.orgScheduleSettings,
      });
    },
  });

  useEffect(() => {
    getScheduleSettings();
  }, [getScheduleSettings]);

  const updateSettings = (settings: OrgSettings) => {
    dispatch({
      type: 'UPDATE_SETTINGS',
      settings: settings,
    });
  }

  return (
    <OrgSettingsContext.Provider value={{
      settings: settings,
      updateSettings: updateSettings,
    }}>
      {children}
    </OrgSettingsContext.Provider>
  );
}