import { Button, Colors, DatePicker, Modal, ModalBody, ModalFooter, ModalHeader, Products, SchedulingRoles, StyledParagraph, TextField, useAuthState, useForm, View } from "@barscience/global-components";
import { useState } from "react";
import useOrgSettings from "../../../components/config/useOrgSettings";
import AvailabilityDayEditor from "./AvailabilityDayEditor";
import { Availability, AvailabilityInput, AvailabilityRange, DayOfWeek, getDayID, validateCustomAvailability } from "./availabilityUtils";

export type AvailabilityFormInput = {
  startDate: string;
  minShiftsPerWeek: string;
  maxShiftsPerWeek: string;
  minHoursPerWeek: string;
  maxHoursPerWeek: string;
  mondayAvailability: string;
  mondayAvailabilityReason: string;
  mondayPartialAvailabilityType: string;
  mondayPartialAvailabilityTime: string;
  tuesdayAvailability: string;
  tuesdayAvailabilityReason: string;
  tuesdayPartialAvailabilityType: string;
  tuesdayPartialAvailabilityTime: string;
  wednesdayAvailability: string;
  wednesdayAvailabilityReason: string;
  wednesdayPartialAvailabilityType: string;
  wednesdayPartialAvailabilityTime: string;
  thursdayAvailability: string;
  thursdayAvailabilityReason: string;
  thursdayPartialAvailabilityType: string;
  thursdayPartialAvailabilityTime: string;
  fridayAvailability: string;
  fridayAvailabilityReason: string;
  fridayPartialAvailabilityType: string;
  fridayPartialAvailabilityTime: string;
  saturdayAvailability: string;
  saturdayAvailabilityReason: string;
  saturdayPartialAvailabilityType: string;
  saturdayPartialAvailabilityTime: string;
  sundayAvailability: string;
  sundayAvailabilityReason: string;
  sundayPartialAvailabilityType: string;
  sundayPartialAvailabilityTime: string;
}

type AvailabilityEditorProps = {
  modalTitle: string;
  submitLabel: string;
  isLoading: boolean;
  initialAvailability?: Availability;
  onSubmit: (values: AvailabilityInput) => void | Promise<void>;
  handleClose: () => void;
  error?: string | null;
}

export default function AvailabilityEditor(props: AvailabilityEditorProps) {
  const { state } = useAuthState();
  const { settings } = useOrgSettings();

  const getDayInitialAvailability = (day: DayOfWeek) => {
    const times = props.initialAvailability?.availability[day];
    if (!times) {
      return 'AVAILABLE';
    }

    if (times.length === 1) {
      if (times[0].isAvailable) {
        return 'AVAILABLE';
      } else {
        return 'UNAVAILABLE';
      }
    } else {
      return 'PARTIALLY_AVAILABLE';
    }
  }

  const getDayInitialReason = (day: DayOfWeek) => {
    const times = props.initialAvailability?.availability[day];
    if (!times) {
      return '';
    }

    if (times.length === 1) {
      if (!times[0].isAvailable) {
        return times[0].reason || '';
      }
    } else if (times.length === 2) {
      if (!times[0].isAvailable) {
        return times[0].reason || '';
      } else {
        return times[1].reason || '';
      }
    }

    return '';
  }

  const getDayInitialPartialAvailabilityType = (day: DayOfWeek) => {
    const times = props.initialAvailability?.availability[day];
    if (!times) {
      return 'BEFORE';
    }

    if (times.length === 2 && !times[0].isAvailable) {
      return 'AFTER';
    }

    if (times.length > 2) {
      return 'CUSTOM';
    }

    return 'BEFORE';
  }

  const getDayInitialPartialAvailabilityTime = (day: DayOfWeek) => {
    const times = props.initialAvailability?.availability[day];
    if (!times) {
      return '16:00';
    }

    if (times.length === 2) {
      if (!times[0].isAvailable) {
        return times[1].startTime;
      } else {
        return times[0].endTime;
      }
    }

    return '16:00';
  }

  const getDayInitialCustomRanges = (day: DayOfWeek) => {
    const times = props.initialAvailability?.availability[day];
    if (!times) {
      return [{
        startTime: '',
        endTime: '',
        isAvailable: false,
        reason: '',
      }];
    }

    if (getDayInitialPartialAvailabilityType(day) === 'CUSTOM') {
      return times.filter((time) => !time.isAvailable);
    }

    return [{
      startTime: '',
      endTime: '',
      isAvailable: false,
      reason: '',
    }];
  }

  const handleSubmit = async (values: AvailabilityFormInput) => {
    props.onSubmit({
      startDate: values.startDate,
      minShiftsPerWeek: parseInt(values.minShiftsPerWeek),
      maxShiftsPerWeek: parseInt(values.maxShiftsPerWeek),
      minHoursPerWeek: parseInt(values.minHoursPerWeek),
      maxHoursPerWeek: parseInt(values.maxHoursPerWeek),
      monday: mapInputToAvailabilityRanges(values.mondayAvailability, values.mondayAvailabilityReason, values.mondayPartialAvailabilityType, values.mondayPartialAvailabilityTime, customValues.monday),
      tuesday: mapInputToAvailabilityRanges(values.tuesdayAvailability, values.tuesdayAvailabilityReason, values.tuesdayPartialAvailabilityType, values.tuesdayPartialAvailabilityTime, customValues.tuesday),
      wednesday: mapInputToAvailabilityRanges(values.wednesdayAvailability, values.wednesdayAvailabilityReason, values.wednesdayPartialAvailabilityType, values.wednesdayPartialAvailabilityTime, customValues.wednesday),
      thursday: mapInputToAvailabilityRanges(values.thursdayAvailability, values.thursdayAvailabilityReason, values.thursdayPartialAvailabilityType, values.thursdayPartialAvailabilityTime, customValues.thursday),
      friday: mapInputToAvailabilityRanges(values.fridayAvailability, values.fridayAvailabilityReason, values.fridayPartialAvailabilityType, values.fridayPartialAvailabilityTime, customValues.friday),
      saturday: mapInputToAvailabilityRanges(values.saturdayAvailability, values.saturdayAvailabilityReason, values.saturdayPartialAvailabilityType, values.saturdayPartialAvailabilityTime, customValues.saturday),
      sunday: mapInputToAvailabilityRanges(values.sundayAvailability, values.sundayAvailabilityReason, values.sundayPartialAvailabilityType, values.sundayPartialAvailabilityTime, customValues.sunday),
    });
  }

  const [customValues, setCustomValues] = useState<{
    monday: AvailabilityRange[];
    tuesday: AvailabilityRange[];
    wednesday: AvailabilityRange[];
    thursday: AvailabilityRange[];
    friday: AvailabilityRange[];
    saturday: AvailabilityRange[];
    sunday: AvailabilityRange[];
  }>({
    monday: getDayInitialCustomRanges('monday'),
    tuesday: getDayInitialCustomRanges('tuesday'),
    wednesday: getDayInitialCustomRanges('wednesday'),
    thursday: getDayInitialCustomRanges('thursday'),
    friday: getDayInitialCustomRanges('friday'),
    saturday: getDayInitialCustomRanges('saturday'),
    sunday: getDayInitialCustomRanges('sunday'),
  });

  const form = useForm<AvailabilityFormInput>({
    initialValues: {
      startDate: props.initialAvailability ? props.initialAvailability.startDate : '',
      minShiftsPerWeek: props.initialAvailability ? props.initialAvailability.minShiftsPerWeek.toString() : '',
      maxShiftsPerWeek: props.initialAvailability ? props.initialAvailability.maxShiftsPerWeek.toString() : '',
      minHoursPerWeek: props.initialAvailability ? props.initialAvailability.minHoursPerWeek.toString() : '',
      maxHoursPerWeek: props.initialAvailability ? props.initialAvailability.maxHoursPerWeek.toString() : '',
      mondayAvailability: props.initialAvailability ? getDayInitialAvailability('monday') : 'AVAILABLE',
      mondayAvailabilityReason: props.initialAvailability ? getDayInitialReason('monday') : '',
      mondayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('monday') : 'BEFORE',
      mondayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('monday') : '16:00',
      tuesdayAvailability: props.initialAvailability ? getDayInitialAvailability('tuesday') : 'AVAILABLE',
      tuesdayAvailabilityReason: props.initialAvailability ? getDayInitialReason('tuesday') : '',
      tuesdayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('tuesday') : 'BEFORE',
      tuesdayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('tuesday') : '16:00',
      wednesdayAvailability: props.initialAvailability ? getDayInitialAvailability('wednesday') : 'AVAILABLE',
      wednesdayAvailabilityReason: props.initialAvailability ? getDayInitialReason('wednesday') : '',
      wednesdayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('wednesday') : 'BEFORE',
      wednesdayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('wednesday') : '16:00',
      thursdayAvailability: props.initialAvailability ? getDayInitialAvailability('thursday') : 'AVAILABLE',
      thursdayAvailabilityReason: props.initialAvailability ? getDayInitialReason('thursday') : '',
      thursdayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('thursday') : 'BEFORE',
      thursdayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('thursday') : '16:00',
      fridayAvailability: props.initialAvailability ? getDayInitialAvailability('friday') : 'AVAILABLE',
      fridayAvailabilityReason: props.initialAvailability ? getDayInitialReason('friday') : '',
      fridayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('friday') : 'BEFORE',
      fridayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('friday') : '16:00',
      saturdayAvailability: props.initialAvailability ? getDayInitialAvailability('saturday') : 'AVAILABLE',
      saturdayAvailabilityReason: props.initialAvailability ? getDayInitialReason('saturday') : '',
      saturdayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('saturday') : 'BEFORE',
      saturdayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('saturday') : '16:00',
      sundayAvailability: props.initialAvailability ? getDayInitialAvailability('sunday') : 'AVAILABLE',
      sundayAvailabilityReason: props.initialAvailability ? getDayInitialReason('sunday') : '',
      sundayPartialAvailabilityType: props.initialAvailability ? getDayInitialPartialAvailabilityType('sunday') : 'BEFORE',
      sundayPartialAvailabilityTime: props.initialAvailability ? getDayInitialPartialAvailabilityTime('sunday') : '16:00',
    },
    onSubmit: handleSubmit,
  });

  const getDayReason = (name: string) => {
    switch (name) {
      case 'mondayAvailability':
        return form.values.mondayAvailabilityReason;
      case 'tuesdayAvailability':
        return form.values.tuesdayAvailabilityReason;
      case 'wednesdayAvailability':
        return form.values.wednesdayAvailabilityReason;
      case 'thursdayAvailability':
        return form.values.thursdayAvailabilityReason;
      case 'fridayAvailability':
        return form.values.fridayAvailabilityReason;
      case 'saturdayAvailability':
        return form.values.saturdayAvailabilityReason;
      case 'sundayAvailability':
        return form.values.sundayAvailabilityReason;
      default:
        return '';
    }
  }

  const getDayAvailabilityType = (dayAvailabilityFieldName: string) => {
    const day = dayAvailabilityFieldName.split('Availability')[0];

    switch (day) {
      case 'monday':
        return form.values.mondayPartialAvailabilityType;
      case 'tuesday':
        return form.values.tuesdayPartialAvailabilityType;
      case 'wednesday':
        return form.values.wednesdayPartialAvailabilityType;
      case 'thursday':
        return form.values.thursdayPartialAvailabilityType;
      case 'friday':
        return form.values.fridayPartialAvailabilityType;
      case 'saturday':
        return form.values.saturdayPartialAvailabilityType;
      case 'sunday':
        return form.values.sundayPartialAvailabilityType;
      default:
        return '';
    }
  }

  const isCustomAvailabilityValid = () => {
    if (form.values.mondayAvailability === 'PARTIALLY_AVAILABLE' && form.values.mondayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.monday)) {
        return false;
      }
    }

    if (form.values.tuesdayAvailability === 'PARTIALLY_AVAILABLE' && form.values.tuesdayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.tuesday)) {
        return false;
      }
    }

    if (form.values.wednesdayAvailability === 'PARTIALLY_AVAILABLE' && form.values.wednesdayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.wednesday)) {
        return false;
      }
    }

    if (form.values.thursdayAvailability === 'PARTIALLY_AVAILABLE' && form.values.thursdayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.thursday)) {
        return false;
      }
    }

    if (form.values.fridayAvailability === 'PARTIALLY_AVAILABLE' && form.values.fridayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.friday)) {
        return false;
      }
    }

    if (form.values.saturdayAvailability === 'PARTIALLY_AVAILABLE' && form.values.saturdayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.saturday)) {
        return false;
      }
    }

    if (form.values.sundayAvailability === 'PARTIALLY_AVAILABLE' && form.values.sundayPartialAvailabilityType === 'CUSTOM') {
      if (!validateCustomAvailability(customValues.sunday)) {
        return false;
      }
    }

    return true;
  }

  const handleDayAvailabilityChange = (name: string, value: string) => {
    if (value === 'AVAILABLE') {
      form.handleValidate(name + 'Reason', null);
    }

    if (value === 'UNAVAILABLE' || (value === 'PARTIALLY_UNAVAILABLE' && (getDayAvailabilityType(name) === 'BEFORE' || getDayAvailabilityType(name) === 'AFTER'))) {
      const reason = getDayReason(name);
      if (!reason) {
        form.handleValidate(name + 'Reason', '');
      }
    }

    return null;
  }

  return (
    <Modal
      style={{
        maxHeight: 'min(90vh, 800px)'
      }}
      handleClose={props.handleClose}
      header={<ModalHeader title={props.modalTitle} showCloseButton={false} />}
      body={
        <ModalBody>
          <View style={{ gap: '16px', overflowY: 'auto', minHeight: 'fit-content' }}>
            <View style={{ gap: '16px', minHeight: 'fit-content' }}>
              <DatePicker name='startDate' label='Effective date' required value={form.values.startDate} onChange={form.handleChange} onValidate={form.handleValidate} error={form.errors.startDate} dateFilter={(date) => {
                const dayID = getDayID(settings.startDayOfWeek);

                let today = new Date();
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

                if (today > date && !(state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin || state.user?.roles[Products.Scheduling] === SchedulingRoles.Manager)) {
                  return false;
                }

                if (dayID === date.getDay()) {
                  return true;
                }

                return false;
              }} />

              <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                <TextField name='minShiftsPerWeek' label='Min. shifts per week' type='number' required validate={(_, value) => {
                  if (parseInt(value) > parseInt(form.values.maxShiftsPerWeek)) {
                    return 'Must be less than max shifts';
                  } else {
                    form.handleValidate('maxShiftsPerWeek', null);
                    return null;
                  }
                }} value={form.values.minShiftsPerWeek} onChange={form.handleChange} onValidate={form.handleValidate} error={form.errors.minShiftsPerWeek} />
                <TextField name='maxShiftsPerWeek' label='Max. shifts per week' type='number' required validate={(_, value) => {
                  if (parseInt(value) < parseInt(form.values.minShiftsPerWeek)) {
                    return 'Must be greater than min shifts';
                  } else {
                    form.handleValidate('minShiftsPerWeek', null);
                    return null;
                  }
                }} value={form.values.maxShiftsPerWeek} onChange={form.handleChange} onValidate={form.handleValidate} error={form.errors.maxShiftsPerWeek} />
              </View>

              <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                <TextField name='minHoursPerWeek' label='Min. hours per week' type='number' required validate={(_, value) => {
                  if (parseInt(value) > parseInt(form.values.maxHoursPerWeek)) {
                    return 'Must be less than max hours';
                  } else {
                    form.handleValidate('maxHoursPerWeek', null);
                    return null;
                  }
                }} value={form.values.minHoursPerWeek} onChange={form.handleChange} onValidate={form.handleValidate} error={form.errors.minHoursPerWeek} />
                <TextField name='maxHoursPerWeek' label='Max. hours per week' type='number' required validate={(_, value) => {
                  if (parseInt(value) < parseInt(form.values.minHoursPerWeek)) {
                    return 'Must be greater than min hours';
                  } else {
                    form.handleValidate('minHoursPerWeek', null);
                    return null;
                  }
                }} value={form.values.maxHoursPerWeek} onChange={form.handleChange} onValidate={form.handleValidate} error={form.errors.maxHoursPerWeek} />
              </View>
            </View>

            <View style={{ gap: '48px', marginTop: '48px' }}>
              <AvailabilityDayEditor day='monday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='tuesday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='wednesday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='thursday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='friday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='saturday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
              <AvailabilityDayEditor day='sunday' handleDayAvailabilityChange={handleDayAvailabilityChange} values={form.values} errors={form.errors} customValues={customValues} handleChange={form.handleChange} handleValidate={form.handleValidate} setCustomValues={setCustomValues} />
            </View>
          </View>
        </ModalBody>
      }
      footer={
        <ModalFooter onClose={props.handleClose}>
          {({ closeModal }) => (
            <View style={{ gap: '16px', width: '100%' }}>
              {props.error && <StyledParagraph style={{ color: Colors.error500, textAlign: 'center' }}>{props.error}</StyledParagraph>}
              <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
                <Button label='Cancel' role='button' variant='tertiary' action={closeModal} />
                <Button label={props.submitLabel} role='button' variant='primary' action={() => { handleSubmit(form.values); }} loading={props.isLoading} disabled={form.hasError || !isCustomAvailabilityValid()} />
              </View>
            </View>
          )}
        </ModalFooter>
      }
    />
  );
}

const mapInputToAvailabilityRanges = (availability: string, reason: string, partialAvailabilityType: string, partialAvailabilityTime: string, customAvailability: AvailabilityRange[]) => {
  const times: AvailabilityRange[] = [];

  if (availability === 'AVAILABLE') {
    times.push(
      {
        startTime: '00:00',
        endTime: '23:59',
        isAvailable: true,
        reason: null,
      }
    );
  } else if (availability === 'UNAVAILABLE') {
    times.push(
      {
        startTime: '00:00',
        endTime: '23:59',
        isAvailable: false,
        reason: reason,
      }
    );
  } else if (availability === 'PARTIALLY_AVAILABLE') {
    if (partialAvailabilityType === 'BEFORE') {
      times.push({
        startTime: '00:00',
        endTime: partialAvailabilityTime,
        isAvailable: true,
        reason: null,
      });

      times.push({
        startTime: partialAvailabilityTime,
        endTime: '23:59',
        isAvailable: false,
        reason: reason,
      });
    } else if (partialAvailabilityType === 'AFTER') {
      times.push({
        startTime: '00:00',
        endTime: partialAvailabilityTime,
        isAvailable: false,
        reason: reason,
      });

      times.push({
        startTime: partialAvailabilityTime,
        endTime: '23:59',
        isAvailable: true,
        reason: null,
      });
    } else if (partialAvailabilityType === 'CUSTOM') {
      customAvailability.forEach((range, index) => {
        if (index === 0 && range.startTime !== '00:00') {
          times.push({
            startTime: '00:00',
            endTime: range.startTime,
            isAvailable: true,
            reason: null,
          });
        }

        // Add available gap, if needed
        if (times.length > 0) {
          const lastRange = times[times.length - 1];
          if (lastRange.endTime !== range.startTime) {
            times.push({
              startTime: lastRange.endTime,
              endTime: range.startTime,
              isAvailable: true,
              reason: null,
            });
          }
        }

        times.push({
          startTime: range.startTime,
          endTime: range.endTime,
          isAvailable: false,
          reason: range.reason,
        });

        // Check for ending time range
        if (index === customAvailability.length - 1 && range.endTime !== '23:59') {
          times.push({
            startTime: range.endTime,
            endTime: '23:59',
            isAvailable: true,
            reason: null,
          });
        }
      })
    } else {
      console.log(`Invalid partial availability type: ${partialAvailabilityType}`);
    }
  } else {
    console.log(`Invalid availability type: ${availability}`);
  }


  return times;
}