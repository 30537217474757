import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, DatePicker, ErrorPage, FormModal, FormModalValueProvider, generateId, Icon, Icons, InfoModal, ModalLauncher, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, Tooltip, useAlertState, useAuthState, View } from "@barscience/global-components";
import { availabilityTimes } from "./staff/availabilities/availabilityTimes";

/* Get Requests Query */
export const GET_USER_REQUESTS = gql`
query getUserTimeOffAndRequests($id: ID!, $startDate: Date!, $endDate: Date!) {
  scheduleUser(id: $id) {
    timeOffRequests(startDate: $startDate, endDate: $endDate) {
      id
      created
      comments
      type {
        id
        name
        isRequestToWork
      }
      days {
        id
        date
        startTime
        endTime
        status
        reviewed {
          user {
            id
            firstName
            lastName
          }
          timestamp
          comments
        }
      }
    }
  }
}
`;

export type GetUserTimeOffAndRequestsResponse = {
  scheduleUser: {
    timeOffRequests: TimeOffRequest[];
  } | null;
}

type TimeOffRequest = {
  id: string;
  created: string;
  comments: string | null;
  type: {
    id: string;
    name: string;
    isRequestToWork: boolean;
  };
  days: {
    id: string;
    date: string;
    startTime: string;
    endTime: string;
    status: string;
    reviewed: {
      user: {
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      timestamp: string;
      comments: string | null;
    } | null;
  }[];
}

/* Get Request Types Query */
export const GET_REQUEST_TYPES = gql`
query getTypesForTimeOffRequests {
  timeOffRequestTypes {
    id
    name
    description
    isRequestToWork
    requireApproval
  }
}
`;

export type GetRequestTypesResponse = {
  timeOffRequestTypes: {
    id: string;
    name: string;
    description: string | null;
    isRequestToWork: boolean;
    requireApproval: boolean;
  }[];
}

/* Create Request Mutation */
const CREATE_REQUEST = gql`
mutation createTimeOffRequestForSelf($typeId: ID!, $days: [TimeOffRequestDayInput!]!, $comments: String) {
  createTimeOffRequest(typeID: $typeId, days: $days, comments: $comments) {
    id
    created
    comments
    type {
      id
      name
      isRequestToWork
    }
    days {
      id
      date
      startTime
      endTime
      status
      reviewed {
        user {
          id
          firstName
          lastName
        }
        timestamp
        comments
      }
    }
  }
}
`;

type CreateRequestResponse = {
  createTimeOffRequest: TimeOffRequest;
}

/* Delete Request Mutation */
const DELETE_REQUEST = gql`
mutation deleteTimeOffRequest($id: ID!) {
  success: deleteTimeOffRequest(id: $id)
}
`;

type DeleteRequestResponse = {
  success: boolean;
}

type CreateRequestInput = {
  typeId: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isAllDay: boolean;
  comments: string;
}

export default function TimeOffAndRequests() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: requestData, loading: requestsAreLoading, error: requestsError } = useQuery<GetUserTimeOffAndRequestsResponse>(GET_USER_REQUESTS, {
    variables: {
      id: state.user?.id,
      startDate: new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
      endDate: new Date((new Date().setFullYear(new Date().getFullYear() + 1))).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
    },
  });
  const { data: requestTypesData, loading: requestTypesAreLoading, error: requestTypesError } = useQuery<GetRequestTypesResponse>(GET_REQUEST_TYPES, {
    fetchPolicy: 'cache-first',
  });
  const [createRequest] = useMutation<CreateRequestResponse>(CREATE_REQUEST, {
    update(cache, { data: resultData }) {
      if (!resultData?.createTimeOffRequest || !requestData?.scheduleUser?.timeOffRequests) {
        return;
      }

      cache.updateQuery<GetUserTimeOffAndRequestsResponse>({
        query: GET_USER_REQUESTS,
        variables: {
          id: state.user?.id,
          startDate: new Date().toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }),
          endDate: new Date((new Date().setFullYear(new Date().getFullYear() + 1))).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }),
        }
      }, (data) => {
        return {
          scheduleUser: {
            timeOffRequests: [resultData.createTimeOffRequest, ...(data?.scheduleUser?.timeOffRequests || [])].sort((a, b) => {
              const aDate = new Date(a.days[0].date);
              const bDate = new Date(b.days[0].date);

              if (aDate < bDate) {
                return -1;
              } else if (aDate > bDate) {
                return 1;
              } else {
                return 0;
              }
            })
          }
        }
      })
    }
  });
  const [deleteRequest] = useMutation<DeleteRequestResponse>(DELETE_REQUEST, {
    update(cache, { data: resultData }, { variables }) {
      if (!resultData?.success || !requestData?.scheduleUser?.timeOffRequests || !variables?.id) {
        return;
      }

      const request = requestData.scheduleUser.timeOffRequests.find((req) => req.id === variables.id);
      if (!request) {
        return;
      }

      cache.evict({
        id: cache.identify(request),
      });
    }
  });

  /* Create Request */
  const handleCreateRequest = async (values: CreateRequestInput) => {
    let days = [];

    let currentDate = new Date(values.startDate);
    while (currentDate <= new Date(values.endDate)) {
      const formattedDate = currentDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })

      if (values.isAllDay) {
        days.push({
          date: formattedDate,
          startTime: '00:00',
          endTime: '23:59',
        });
      } else {
        if (values.startDate === values.endDate) {
          days.push({
            date: formattedDate,
            startTime: values.startTime,
            endTime: values.endTime,
          })
        } else {
          days.push({
            date: formattedDate,
            startTime: formattedDate === values.startDate ? values.startTime : '00:00',
            endTime: formattedDate === values.endDate ? values.endTime : '23:59',
          })
        }
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    const { errors } = await createRequest({
      variables: {
        typeId: values.typeId,
        comments: values.comments ? values.comments : null,
        days: days,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating request' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request created' type='success' id={id} />
      addAlert(id, alert);
    }
  };

  const createRequestModal = (
    <FormModal<CreateRequestInput> title='Create Request' submitLabel='Create' onSubmit={handleCreateRequest} initialValues={{ typeId: '', startDate: '', startTime: '', endDate: '', endTime: '', isAllDay: true, comments: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => {
          const selectedTypeId = getValue?.('typeId');
          const selectedType = requestTypesData?.timeOffRequestTypes.find((requestType) => requestType.id === selectedTypeId);
          const requireComments = selectedType ? (selectedType?.requireApproval || !selectedType?.isRequestToWork) : false;

          return (
            <View style={{ gap: '16px' }}>
              {requestTypesAreLoading ?
                <CircularSpinner size='medium' />
                :
                <>
                  <SingleSelect label='Type' name='typeId' required>
                    {requestTypesData?.timeOffRequestTypes.map((requestType) => (
                      <Choice label={requestType.name} description={requestType.description || undefined} value={requestType.id} key={requestType.id} />
                    ))}
                  </SingleSelect>

                  <Checkbox label='All day' name='isAllDay' description='Uncheck to request partial days' validate={(_, value) => {
                    if (value) {
                      setError?.('startTime', null);
                      setError?.('endTime', null);
                    }
                  }} />

                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <DatePicker label='Start Date' name='startDate' required style={{ width: '50%' }} validationDependencies={['endDate']} dateFilter={(date: Date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);

                      let endDate = null;
                      if (getValue?.('endDate')) {
                        endDate = new Date(getValue?.('endDate'));
                      }

                      if (date < today) {
                        return false;
                      }

                      if (endDate && date > endDate) {
                        return false;
                      }

                      // Check for overlapping time off requests
                      if (requestData?.scheduleUser?.timeOffRequests) {
                        for (const request of requestData?.scheduleUser?.timeOffRequests) {
                          if (request.days.some((day) => day.date === date.toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          }))) {
                            return false;
                          }
                        }
                      }

                      return true;
                    }} />

                    {!getValue?.('isAllDay') &&
                      <SingleSelect label='Start Time' name='startTime' required={!getValue?.('isAllDay')} style={{ width: '50%' }} filterable autoFocusSearch>
                        {
                          availabilityTimes.map((time) => (
                            <Choice label={time.label} value={time.value} key={time.value} />
                          ))
                        }
                      </SingleSelect>
                    }
                  </View>

                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <DatePicker label='End Date' name='endDate' required style={{ width: '50%' }} validationDependencies={['startDate']} dateFilter={(date: Date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);

                      let startDate = null;
                      if (getValue?.('startDate')) {
                        startDate = new Date(getValue?.('startDate'));
                      }

                      if (date < today) {
                        return false;
                      }

                      if (startDate && date < startDate) {
                        return false;
                      }

                      // Check for overlapping time off requests
                      if (requestData?.scheduleUser?.timeOffRequests) {
                        for (const request of requestData?.scheduleUser?.timeOffRequests) {
                          if (request.days.some((day) => day.date === date.toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          }))) {
                            return false;
                          }
                        }
                      }

                      if (getValue?.('startDate') && requestData?.scheduleUser?.timeOffRequests && requestData.scheduleUser.timeOffRequests.length > 0) {
                        // Find the earliest existing request start date that is greater than the new start date
                        let selectedStartDate = new Date(getValue?.('startDate'));
                        selectedStartDate.setHours(0, 0, 0, 0);

                        let maxEndDate = null;

                        for (const request of requestData.scheduleUser.timeOffRequests) {
                          let startDate = new Date(request.days[0].date);
                          startDate.setHours(0, 0, 0, 0);

                          if (startDate > selectedStartDate) {
                            if (!maxEndDate || startDate < maxEndDate) {
                              maxEndDate = startDate;
                            }
                          }
                        }

                        if (maxEndDate && date >= maxEndDate) {
                          return false;
                        }
                      }

                      return true;
                    }} />

                    {!getValue?.('isAllDay') &&
                      <SingleSelect label='End Time' name='endTime' required={!getValue?.('isAllDay')} style={{ width: '50%' }} filterable autoFocusSearch>
                        {
                          availabilityTimes.map((time) => (
                            <Choice label={time.label} value={time.value} key={time.value} />
                          ))
                        }
                      </SingleSelect>
                    }
                  </View>

                  <TextArea label='Comments' name='comments' required={requireComments} validationDependencies={[requireComments]} />
                </>
              }
            </View>
          );
        }}
      </FormModalValueProvider>
    </FormModal >
  );

  /* Request Comments */
  function RequestCommentModal(props: { request: TimeOffRequest, handleClose?: () => void }) {
    return (
      <InfoModal title='Request Info' handleClose={props.handleClose}>
        <View style={{ gap: '24px' }}>
          <View style={{ marginTop: '16px' }}>
            <StyledParagraph bold>Comments:</StyledParagraph>
            <StyledParagraph>{props.request.comments ? props.request.comments : '-----'}</StyledParagraph>
          </View>

          <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>Created {new Date(props.request.created).toLocaleString()}</StyledParagraph>
        </View>
      </InfoModal>
    );
  }

  /* Delete Request */
  const handleDeleteRequest = async (id: string) => {
    if (!id) {
      return;
    }

    const { errors } = await deleteRequest({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting request' description={errors[0].message} type='error' id={id} />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request deleted' type='success' id={id} />;
      addAlert(id, alert);
    }
  }

  const deleteRequestModal = (
    <ConfirmModal title='Delete Request?' onConfirm={handleDeleteRequest} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This request will be permanently deleted.</StyledParagraph>
        <StyledParagraph bold>If the cutoff time for these dates has passed, you will not be able to resubmit this request.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (requestsError || requestTypesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Time Off & Requests</StyledHeading>

          <ModalLauncher modal={createRequestModal}>
            {({ openModal }) => (
              <Button label='Create Request' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {requestsAreLoading ?
          <View>
            <AtomSpinner size='large' />
          </View>
          :
          <>
            {requestData?.scheduleUser?.timeOffRequests.length === 0 ?
              <View style={{ alignItems: 'center', justifyContent: 'center', minHeight: '40vh', width: '100%' }}>
                <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>You have no upcoming time off requests.</StyledHeading>
              </View>
              :
              <View style={{ gap: '16px', maxWidth: '800px' }}>
                {requestData?.scheduleUser?.timeOffRequests.map((request) => {
                  return (
                    <Card key={request.id}>
                      <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                        <View style={{ gap: '4px' }}>
                          <StyledParagraph bold style={{ fontSize: '18px' }}>{getRequestDateLabel(request.days)}</StyledParagraph>
                          <StyledParagraph style={{ color: Colors.neutral700 }}>{request.type.name} {request.type.isRequestToWork ? <span style={{ color: Colors.primary500 }}>(Request to work)</span> : ''}</StyledParagraph>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px' }}>
                          {getStatusLabel(request.days)}

                          <ModalLauncher modal={<RequestCommentModal request={request} />}>
                            {({ openModal: openInfoModal }) => (
                              <ModalLauncher modal={deleteRequestModal}>
                                {({ openModal: openDeleteModal }) => (
                                  <ActionMenu>
                                    <ActionItem label='View comments' onClick={openInfoModal} />
                                    <ActionItem label='Delete' onClick={() => { openDeleteModal(request.id); }} />
                                  </ActionMenu>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        </View>
                      </View>
                    </Card>
                  );
                })}
              </View>
            }
          </>
        }
      </Cell>
    </StandardGrid>
  );
}

const getRequestDateLabel = (days: TimeOffRequest['days']): string => {
  if (days.length === 1) {
    if (days[0].startTime === '00:00' && days[0].endTime === '23:59') {
      return `${days[0].date} (All day)`;
    } else {
      return `${days[0].date} (${formatTime(days[0].startTime)} - ${formatTime(days[0].endTime)})`;
    }
  } else {
    return `${days[0].date} ${days[0].startTime !== '00:00' ? `(${formatTime(days[0].startTime)})` : ''} - ${days[days.length - 1].date} ${days[days.length - 1].endTime !== '23:59' ? `(${formatTime(days[days.length - 1].endTime)})` : ''}`;
  }
}

const formatTime = (time: string): string => {
  let [hour, minute] = time.split(':');

  let dayPart = 'AM';
  if (parseInt(hour) > 11) {
    hour = (parseInt(hour) - 12).toString();
    dayPart = 'PM';
  }

  if (hour === '00') {
    hour = '12';
  }

  hour = parseInt(hour).toString();

  return `${hour}:${minute} ${dayPart}`;
}

const getStatusLabel = (days: TimeOffRequest['days']) => {
  let isAllApproved = true;
  let isAllDeclined = true;
  let isAllPending = true;

  for (let day of days) {
    if (day.status !== 'APPROVED') {
      isAllApproved = false;
    }

    if (day.status !== 'DECLINED') {
      isAllDeclined = false;
    }

    if (day.status !== 'PENDING_APPROVAL') {
      isAllPending = false;
    }
  }

  if (isAllApproved) {
    let maxApprover = `${days[0].reviewed?.user?.firstName} ${days[0].reviewed?.user?.lastName}`;
    let maxApproveTime = new Date(days[0].reviewed?.timestamp || '');
    for (let day of days) {
      if (new Date(day.reviewed?.timestamp || '') > maxApproveTime) {
        maxApproveTime = new Date(day.reviewed?.timestamp || '');
        maxApprover = `${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`;
      }
    }

    if (!days[0].reviewed?.user) {
      maxApprover = 'Automatic Approval';
    }

    return (
      <Tooltip content={
        <View>
          <StyledParagraph>Approved {maxApproveTime.toLocaleString()} by {maxApprover}</StyledParagraph>
        </View>
      }>
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
          <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
        </View>
      </Tooltip>
    );
  } else if (isAllDeclined) {
    let maxApprover = `${days[0].reviewed?.user?.firstName} ${days[0].reviewed?.user?.lastName}`;
    let maxApproveTime = new Date(days[0].reviewed?.timestamp || '');
    for (let day of days) {
      if (new Date(day.reviewed?.timestamp || '') > maxApproveTime) {
        maxApproveTime = new Date(day.reviewed?.timestamp || '');
        maxApprover = `${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`;
      }
    }

    return (
      <Tooltip content={
        <View>
          <StyledParagraph>Declined {maxApproveTime.toLocaleString()} by {maxApprover}</StyledParagraph>
        </View>
      }>
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
          <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
        </View>
      </Tooltip>
    );
  } else if (isAllPending) {
    return (
      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
        <StyledParagraph bold style={{ color: Colors.warning500 }}>Pending approval</StyledParagraph>
      </View>
    );
  } else {
    return (
      <View style={{ alignItems: 'flex-end', gap: '8px' }}>
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.secondary500 }} />
          <StyledParagraph bold style={{ color: Colors.secondary500 }}>Partially reviewed</StyledParagraph>
        </View>

        <ModalLauncher modal={<RequestReviewDetailsModal days={days} />}>
          {({ openModal }) => (
            <Button label='View details' role='button' variant='tertiary' action={() => { openModal(); }} />
          )}
        </ModalLauncher>
      </View>
    );
  }
}

type RequestReviewDetailsModalProps = {
  days: TimeOffRequest['days'];
  handleClose?: () => void;
}

function RequestReviewDetailsModal(props: RequestReviewDetailsModalProps) {
  return (
    <InfoModal title={`${props.days[0].date} - ${props.days[props.days.length - 1].date}`} handleClose={props.handleClose} closeLabel='Close'>
      <View style={{ gap: '24px' }}>
        {props.days.map((day) => {
          return (
            <View key={day.id}>
              <StyledParagraph bold>{day.date} ({(day.startTime === '00:00' && day.endTime === '23:59') ? 'All day' : `${formatTime(day.startTime)} - ${formatTime(day.endTime)}`})</StyledParagraph>

              {day.status === 'PENDING_APPROVAL' &&
                <StyledParagraph style={{ color: Colors.secondary500 }}><span style={{ fontWeight: 700 }}>Pending approval</span></StyledParagraph>
              }

              {day.status === 'DECLINED' &&
                <StyledParagraph style={{ color: Colors.error500 }}><span style={{ fontWeight: 700 }}>Declined</span> {new Date(day.reviewed?.timestamp || '').toLocaleString()} by {`${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`}</StyledParagraph>
              }

              {day.status === 'APPROVED' &&
                <StyledParagraph style={{ color: Colors.primary500 }}><span style={{ fontWeight: 700 }}>Approved</span> {new Date(day.reviewed?.timestamp || '').toLocaleString()} by {`${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`}</StyledParagraph>
              }

              {day.reviewed?.comments &&
                <View style={{ marginTop: '8px' }}>
                  <StyledCaption bold>Comments:</StyledCaption>
                  <StyledParagraph>{day.reviewed.comments}</StyledParagraph>
                </View>
              }
            </View>
          );
        })}
      </View>
    </InfoModal>
  );
}