import { availabilityTimes } from "./availabilityTimes";

export type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type Availability = {
  id: string;
  startDate: string;
  status: 'APPROVED' | 'PENDING_APPROVAL' | 'DECLINED';
  reviewed: {
    reviewer: {
      id: string;
      firstName: string;
      lastName: string;
    };
    comments: string | null;
    timestamp: string;
  } | null;
  minShiftsPerWeek: number;
  maxShiftsPerWeek: number;
  minHoursPerWeek: number;
  maxHoursPerWeek: number;
  created: string;
  availability: {
    monday: AvailabilityRange[];
    tuesday: AvailabilityRange[];
    wednesday: AvailabilityRange[];
    thursday: AvailabilityRange[];
    friday: AvailabilityRange[];
    saturday: AvailabilityRange[];
    sunday: AvailabilityRange[];
  }
}

export type AvailabilityWithUser = {
  id: string;
  startDate: string;
  status: 'APPROVED' | 'PENDING_APPROVAL' | 'DECLINED';
  reviewed: {
    reviewer: {
      id: string;
      firstName: string;
      lastName: string;
    };
    comments: string | null;
    timestamp: string;
  } | null;
  minShiftsPerWeek: number;
  maxShiftsPerWeek: number;
  minHoursPerWeek: number;
  maxHoursPerWeek: number;
  created: string;
  availability: {
    monday: AvailabilityRange[];
    tuesday: AvailabilityRange[];
    wednesday: AvailabilityRange[];
    thursday: AvailabilityRange[];
    friday: AvailabilityRange[];
    saturday: AvailabilityRange[];
    sunday: AvailabilityRange[];
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export type AvailabilityRange = {
  startTime: string;
  endTime: string;
  isAvailable: boolean;
  reason: string | null;
}

export type AvailabilityInput = {
  startDate: string;
  minShiftsPerWeek: number;
  maxShiftsPerWeek: number;
  minHoursPerWeek: number;
  maxHoursPerWeek: number;
  monday: AvailabilityRange[];
  tuesday: AvailabilityRange[];
  wednesday: AvailabilityRange[];
  thursday: AvailabilityRange[];
  friday: AvailabilityRange[];
  saturday: AvailabilityRange[];
  sunday: AvailabilityRange[];
}

export function getDayLabel(day: DayOfWeek) {
  switch (day) {
    case 'monday':
      return 'Monday';
    case 'tuesday':
      return 'Tuesday';
    case 'wednesday':
      return 'Wednesday';
    case 'thursday':
      return 'Thursday';
    case 'friday':
      return 'Friday';
    case 'saturday':
      return 'Saturday';
    case 'sunday':
      return 'Sunday';
    default:
      return 'Unknown day';
  }
}

export function getDayID(day: string) {
  switch (day) {
    case 'SUNDAY':
      return 0;
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
    default:
      return 0;
  }
}

// Returns true if there are any time overlaps in the range of times, and the start and end time provided is an offending overlap.
export function hasTimeOverlap(times: AvailabilityRange[], startTime: string, endTime: string) {
  for (let i = 0; i < times.length; i++) {
    for (let j = i + 1; j < times.length; j++) {
      const firstStart = availabilityTimes.findIndex((time) => time.value === times[i].startTime);
      const firstEnd = availabilityTimes.findIndex((time) => time.value === times[i].endTime);
      const secondStart = availabilityTimes.findIndex((time) => time.value === times[j].startTime);
      const secondEnd = availabilityTimes.findIndex((time) => time.value === times[j].endTime);

      let hasOverlap = false;
      if (firstStart <= secondStart && firstEnd <= secondEnd && firstEnd >= secondStart) {
        hasOverlap = true;
      } else if (firstStart >= secondStart && firstStart <= secondEnd && firstEnd >= secondEnd) {
        hasOverlap = true;
      } else if (firstStart <= secondStart && firstEnd >= secondEnd) {
        hasOverlap = true;
      }

      if (hasOverlap && ((startTime === times[i].startTime && endTime === times[i].endTime) || (startTime === times[j].startTime && endTime === times[j].endTime))) {
        return true;
      }
    }
  }

  return false;
}

// Returns true if there are no overlapping time ranges in the set, and every end time is after the start time. Returns false otherwise.
export function validateCustomAvailability(times: AvailabilityRange[]) {
  // Make sure each end time is after the start time
  for (let i = 0; i < times.length; i++) {
    if (!times[i].startTime || !times[i].endTime) {
      return false;
    }

    if (!times[i].reason) {
      return false;
    }

    const startTime = availabilityTimes.findIndex((time) => time.value === times[i].startTime);
    const endTime = availabilityTimes.findIndex((time) => time.value === times[i].endTime);

    if (endTime <= startTime) {
      return false;
    }
  }

  // Make sure there are no overlapping time ranges
  for (let i = 0; i < times.length; i++) {
    for (let j = i + 1; j < times.length; j++) {
      const firstStart = availabilityTimes.findIndex((time) => time.value === times[i].startTime);
      const firstEnd = availabilityTimes.findIndex((time) => time.value === times[i].endTime);
      const secondStart = availabilityTimes.findIndex((time) => time.value === times[j].startTime);
      const secondEnd = availabilityTimes.findIndex((time) => time.value === times[j].endTime);

      if (firstStart <= secondStart && firstEnd <= secondEnd && firstEnd >= secondStart) {
        return false;
      } else if (firstStart >= secondStart && firstStart <= secondEnd && firstEnd >= secondEnd) {
        return false;
      } else if (firstStart <= secondStart && firstEnd >= secondEnd) {
        return false;
      }
    }
  }

  return true;
}