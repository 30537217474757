import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Colors, ConfirmModal, ErrorPage, FormModal, generateId, HasProductRole, Icon, Icons, ModalLauncher, Products, SchedulingRoles, StandardAlert, StyledCaption, StyledHeading, StyledParagraph, TextArea, Tooltip, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CreateAvailabilityModal from "../availabilities/CreateAvailabilityModal";
import { ScheduleUser } from "../StaffProfile";
import AvailabilityDetails from "../availabilities/AvailabilityDetails";
import { Availability } from "../availabilities/availabilityUtils";
import EditAvailabilityModal from "../availabilities/EditAvailabilityModal";

/* Get User Availabilities */
export const GET_USER_AVAILABILITIES = gql`
query getScheduleUserAvailabilitiesForProfile($id: ID!, $includeUnapproved: Boolean!) {
  scheduleUser(id: $id) {
    currentAndFutureAvailabilities(includeUnapproved: $includeUnapproved) {
      id
      startDate
      status
      reviewed {
        reviewer {
          id
          firstName
          lastName
        }
        comments
        timestamp
      }
      minShiftsPerWeek
      maxShiftsPerWeek
      minHoursPerWeek
      maxHoursPerWeek
      created
      availability {
        monday {
          startTime
          endTime
          isAvailable
          reason
        }
        tuesday {
          startTime
          endTime
          isAvailable
          reason
        }
        wednesday {
          startTime
          endTime
          isAvailable
          reason
        }
        thursday {
          startTime
          endTime
          isAvailable
          reason
        }
        friday {
          startTime
          endTime
          isAvailable
          reason
        }
        saturday {
          startTime
          endTime
          isAvailable
          reason
        }
        sunday {
          startTime
          endTime
          isAvailable
          reason
        }
      }
    }
  }
}
`;

type GetUserAvailabilitiesResponse = {
  scheduleUser: {
    currentAndFutureAvailabilities: Availability[];
  };
}

/* Approve Availability */
export const APPROVE_AVAILABILITY = gql`
mutation approveScheduleAvailability($id: ID!, $comments: String) {
  approveScheduleAvailability(id: $id, comments: $comments) {
    id
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      timestamp
      comments
    }
  }
}
`;

type ApproveAvailabilityInput = {
  comments: string;
}

/* Decline Availability */
export const DECLINE_AVAILABILITY = gql`
mutation declineScheduleAvailability($id: ID!, $comments: String!) {
  declineScheduleAvailability(id: $id, comments: $comments) {
    id
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      comments
      timestamp
    }
  }
}
`;

type DeclineAvailabilityInput = {
  id: string;
  comments: string;
}

/* Delete Availability */
const DELETE_AVAILABILITY = gql`
mutation deleteScheduleAvailability($id: ID!) {
  success: deleteScheduleAvailability(id: $id)
}
`;

type DeleteAvailabilityResponse = {
  success: boolean;
}

/* Page Types */
type StaffAvailabilityPageProps = {
  user: ScheduleUser;
}

export default function AvailabilityPage(props: StaffAvailabilityPageProps) {
  const { userId } = useParams();
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [selectedAvailabilityId, setSelectedAvailabilityId] = useState<string | null>(null);
  const { data, loading, error } = useQuery<GetUserAvailabilitiesResponse>(GET_USER_AVAILABILITIES, {
    variables: {
      id: props.user.id,
      includeUnapproved: true,
    },
    onCompleted: (data) => {
      if (data?.scheduleUser.currentAndFutureAvailabilities.length > 0) {
        setSelectedAvailabilityId(data.scheduleUser.currentAndFutureAvailabilities[0].id);
      }
    }
  });
  const [approveAvailability] = useMutation(APPROVE_AVAILABILITY);
  const [declineAvailability] = useMutation(DECLINE_AVAILABILITY);
  const [deleteAvailability] = useMutation<DeleteAvailabilityResponse>(DELETE_AVAILABILITY, {
    update: (cache, { data: requestData }, { variables }) => {
      if (!requestData?.success || !variables?.id) {
        return;
      }

      const availability = data?.scheduleUser.currentAndFutureAvailabilities.find((a) => a.id === variables.id);
      if (!availability) {
        return;
      }

      cache.evict({
        id: cache.identify(availability)
      });
    }
  });

  /* Approve Availability */
  const handleApproveAvailability = async (values: ApproveAvailabilityInput) => {
    const { errors } = await approveAvailability({
      variables: {
        id: selectedAvailabilityId!,
        comments: values.comments ? values.comments : null,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to approve availability' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const approveModal = (
    <FormModal<ApproveAvailabilityInput> title='Approve Availability' submitLabel='Approve' initialValues={{ comments: '' }} onSubmit={handleApproveAvailability}>
      <TextArea label='Comments' name='comments' />
    </FormModal>
  );

  /* Decline Availability */
  const handleDeclineAvailability = async (values: DeclineAvailabilityInput) => {
    const { errors } = await declineAvailability({
      variables: {
        id: values.id,
        comments: values.comments,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to decline availability' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const declineModal = (
    <FormModal<DeclineAvailabilityInput> title='Decline Availability' submitLabel='Decline' initialValues={{ id: '', comments: '' }} onSubmit={handleDeclineAvailability} destructive>
      <TextArea label='Comments' name='comments' required />
    </FormModal>
  );

  /* Delete Availability */
  const handleDeleteAvailability = async (id: string) => {
    if (!id) {
      return;
    }

    const { errors } = await deleteAvailability({
      variables: {
        id: id,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete availability' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Availability deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteModal = (
    <ConfirmModal title='Delete Availability?' onConfirm={handleDeleteAvailability} confirmLabel='Delete' destructive>
      <StyledParagraph>This availability will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  const selectedAvailability = data?.scheduleUser.currentAndFutureAvailabilities.find((availability) => availability.id === selectedAvailabilityId);

  if (error) {
    return (
      <ErrorPage />
    );
  }

  if (loading) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <AtomSpinner size='large' />
      </Cell>
    );
  }

  return (
    <>
      <Cell lg={12} md={8} sm={4}>
        <Card style={{ display: 'flex', padding: '0', minHeight: '600px' }}>
          <View style={{ flexGrow: 1 }}>
            <View style={{ flexDirection: 'row', flexGrow: 1 }}>
              <View style={{ alignItems: 'center', borderRight: `1px solid ${Colors.neutral300}`, flexGrow: 1, width: '275px' }}>
                {data?.scheduleUser.currentAndFutureAvailabilities.map((availability) => {
                  const isSelected = availability.id === selectedAvailabilityId;

                  return (
                    <View key={availability.id} style={{ borderBottom: `1px solid ${Colors.neutral300}`, cursor: 'pointer', padding: '16px', width: '100%', ...(isSelected ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => setSelectedAvailabilityId(availability.id)}>
                      <StyledHeading tag='h6' style={{ fontSize: '16px' }}>{isDateBeforeToday(new Date(availability.startDate)) ? 'Effective since' : 'Starting on'}<br></br>{availability.startDate}</StyledHeading>
                    </View>
                  );
                })}

                <ModalLauncher modal={<CreateAvailabilityModal userID={userId || ''} />}>
                  {({ openModal }) => (
                    <Button label='Create Availability' role='button' variant='tertiary' action={openModal} leftIcon={Icons.Plus} style={{ marginTop: '12px' }} />
                  )}
                </ModalLauncher>
              </View>
              <View style={{ width: '100%' }}>
                <View style={{ padding: '16px', flexGrow: 1 }}>
                  {selectedAvailability ?
                    <View style={{ gap: '64px', justifyContent: 'space-between' }}>
                      <View style={{ gap: '48px', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Monday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.monday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Tuesday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.tuesday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Wednesday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.wednesday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Thursday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.thursday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Friday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.friday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Saturday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.saturday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Sunday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.sunday} />
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '48px' }}>
                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <View>
                            <StyledCaption>Min. shifts per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.minShiftsPerWeek}</StyledParagraph>
                          </View>
                          <View>
                            <StyledCaption>Max. shifts per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.maxShiftsPerWeek}</StyledParagraph>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <View>
                            <StyledCaption>Min. hours per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.minHoursPerWeek}</StyledParagraph>
                          </View>
                          <View>
                            <StyledCaption>Max. hours per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.maxHoursPerWeek}</StyledParagraph>
                          </View>
                        </View>
                      </View>
                    </View>
                    :
                    <View style={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                      <StyledParagraph style={{ color: Colors.neutral700 }}>Select an availability to view details.</StyledParagraph>
                    </View>
                  }

                </View>
                {selectedAvailability &&
                  <View style={{ borderTop: `1px solid ${Colors.neutral300}`, maxHeight: 'fit-content', padding: '16px', width: '100%' }}>
                    {selectedAvailability?.status === 'APPROVED' &&
                      <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                          <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
                          <Tooltip content={
                            <View style={{ gap: '16px' }}>
                              <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                              <StyledParagraph>{selectedAvailability.reviewed?.reviewer.firstName} {selectedAvailability.reviewed?.reviewer.lastName} approved {new Date(selectedAvailability.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                              {selectedAvailability.reviewed?.comments && <StyledParagraph><span style={{ fontWeight: 'bold' }}>Comments: </span>{selectedAvailability.reviewed?.comments}</StyledParagraph>}
                            </View>
                          }>
                            <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
                          </Tooltip>
                        </View>

                        <ModalLauncher modal={deleteModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ModalLauncher modal={<EditAvailabilityModal initialAvailability={selectedAvailability} />}>
                              {({ openModal: openEditModal }) => (
                                <View style={{ flexDirection: 'row', gap: '16px' }}>
                                  <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin, SchedulingRoles.Manager]}>
                                    <Button label='Edit' variant='secondary' role='button' action={openEditModal} />
                                  </HasProductRole>

                                  <Button label='Delete' variant='secondary' role='button' action={() => { openDeleteModal(selectedAvailabilityId); }} destructive />
                                </View>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </View>
                    }

                    {selectedAvailability?.status === 'PENDING_APPROVAL' &&
                      <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                          <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.warning500 }} />
                          <Tooltip content={
                            <View style={{ gap: '16px' }}>
                              <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                            </View>
                          }>
                            <StyledParagraph bold style={{ color: Colors.warning500 }}>Pending Approval</StyledParagraph>
                          </Tooltip>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '40px' }}>
                          {(state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin || state.user?.roles[Products.Scheduling] === SchedulingRoles.Manager) &&
                            <ModalLauncher modal={approveModal}>
                              {({ openModal: openApproveModal }) => (
                                <ModalLauncher modal={declineModal}>
                                  {({ openModal: openDeclineModal }) => (
                                    <View style={{ flexDirection: 'row', gap: '16px' }}>
                                      <Button label='Approve' variant='primary' role='button' action={openApproveModal} />
                                      <Button label='Decline' variant='secondary' role='button' action={() => { openDeclineModal({ id: selectedAvailability.id, comments: '' }); }} destructive />
                                    </View>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          }

                          {state.user?.id === userId &&
                            <ModalLauncher modal={deleteModal}>
                              {({ openModal: openDeleteModal }) => (
                                <ModalLauncher modal={<EditAvailabilityModal initialAvailability={selectedAvailability} />}>
                                  {({ openModal: openEditModal }) => (
                                    <View style={{ flexDirection: 'row', gap: '16px' }}>
                                      <Button label='Edit' variant='secondary' role='button' action={openEditModal} />
                                      <Button label='Delete' variant='secondary' role='button' action={() => { openDeleteModal(selectedAvailabilityId); }} destructive />
                                    </View>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          }
                        </View>
                      </View>
                    }

                    {selectedAvailability?.status === 'DECLINED' &&
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
                        <Tooltip content={
                          <View style={{ gap: '16px' }}>
                            <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                            <StyledParagraph>{selectedAvailability.reviewed?.reviewer.firstName} {selectedAvailability.reviewed?.reviewer.lastName} declined {new Date(selectedAvailability.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                            {selectedAvailability.reviewed?.comments && <StyledParagraph><span style={{ fontWeight: 'bold' }}>Comments: </span>{selectedAvailability.reviewed?.comments}</StyledParagraph>}
                          </View>
                        }>
                          <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
                        </Tooltip>
                      </View>
                    }
                  </View>
                }
              </View>
            </View>
          </View>
        </Card>
      </Cell>
    </>
  );
}

const isDateBeforeToday = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
};