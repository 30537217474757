import { generateId, StandardAlert, useAlertState } from "@barscience/global-components";
import { gql, useMutation } from "@apollo/client";
import { Availability, AvailabilityInput } from "./availabilityUtils";
import AvailabilityEditor from "./AvailabilityEditor";

/* Edit Availability Mutation */
const EDIT_AVAILABILITY = gql`
mutation editScheduleAvailability($id: ID!, $input: CreateScheduleAvailabilityInput!) {
  editScheduleAvailability(id: $id, input: $input) {
    id
    startDate
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      comments
      timestamp
    }
    minShiftsPerWeek
    maxShiftsPerWeek
    minHoursPerWeek
    maxHoursPerWeek
    created
    availability {
      monday {
        startTime
        endTime
        isAvailable
        reason
      }
      tuesday {
        startTime
        endTime
        isAvailable
        reason
      }
      wednesday {
        startTime
        endTime
        isAvailable
        reason
      }
      thursday {
        startTime
        endTime
        isAvailable
        reason
      }
      friday {
        startTime
        endTime
        isAvailable
        reason
      }
      saturday {
        startTime
        endTime
        isAvailable
        reason
      }
      sunday {
        startTime
        endTime
        isAvailable
        reason
      }
    }
  }
}
`;

type EditAvailabilityModalProps = {
  initialAvailability: Availability;
  handleClose?: () => void;
}

export default function EditAvailabilityModal(props: EditAvailabilityModalProps) {
  const { addAlert } = useAlertState();
  const [editAvailability, { loading, error }] = useMutation(EDIT_AVAILABILITY);

  const handleSubmit = async (values: AvailabilityInput) => {
    const { errors } = await editAvailability({
      variables: {
        id: props.initialAvailability.id,
        input: {
          startDate: values.startDate,
          minShiftsPerWeek: values.minShiftsPerWeek,
          maxShiftsPerWeek: values.maxShiftsPerWeek,
          minHoursPerWeek: values.minHoursPerWeek,
          maxHoursPerWeek: values.maxHoursPerWeek,
          monday: values.monday,
          tuesday: values.tuesday,
          wednesday: values.wednesday,
          thursday: values.thursday,
          friday: values.friday,
          saturday: values.saturday,
          sunday: values.sunday,
        },
      },
    });

    if (!errors) {
      props.handleClose && props.handleClose();

      const id = generateId();
      const alert = <StandardAlert title='Availability updated' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  return (
    <AvailabilityEditor
      modalTitle='Edit Availability'
      submitLabel='Submit changes'
      initialAvailability={props.initialAvailability}
      isLoading={loading}
      onSubmit={handleSubmit}
      error={error ? error.message : null}
      handleClose={props.handleClose ? props.handleClose : () => {}}
    />
  );
}