import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Cell, Checkbox, Choice, CircularSpinner, Colors, DatePicker, ErrorPage, FormModal, FormModalValueProvider, generateId, ModalLauncher, MultiSelect, NoPermission, OptionBar, OptionItem, Products, SchedulingRoles, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextArea, useAlertState, useAuthState, View, WeekPicker } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { GET_REQUEST_TYPES, GET_USER_REQUESTS, GetRequestTypesResponse, GetUserTimeOffAndRequestsResponse } from "../../TimeOffAndRequests";
import CalendarView from "./CalendarView";
import ListView from "./ListView";
import { availabilityTimes } from "../../staff/availabilities/availabilityTimes";
import { GET_ALL_STAFF, GetAllStaffResponse } from "../../staff/StaffList";

/* Get All Requests Query */
const GET_ALL_REQUESTS = gql`
query getAllTimeOffRequests($startDate: Date!, $endDate: Date!) {
  timeOffRequests(startDate: $startDate, endDate: $endDate) {
    id
    type {
      id
      name
      requireApproval
      isRequestToWork
    }
    created
    comments
    user {
      id
      firstName
      lastName
    }
    days {
      id
      date
      startTime
      endTime
      status
      reviewed {
        timestamp
        comments
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

type GetTimeOffRequestsResponse = {
  timeOffRequests: TimeOffRequest[];
}

export type TimeOffRequest = {
  id: string;
  type: {
    id: string;
    name: string;
    requireApproval: boolean;
    isRequestToWork: boolean;
  };
  created: string;
  comments: string | null;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  days: TimeOffRequestDay[];
}

export type TimeOffRequestDay = {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  status: 'APPROVED' | 'PENDING_APPROVAL' | 'DECLINED';
  reviewed: {
    timestamp: string;
    comments: string | null;
    user: {
      id: string;
      firstName: string;
      lastName: string;
    };
  } | null;
}

/* Create Request Mutation */
const CREATE_REQUEST = gql`
mutation createTimeOffRequestAsAdmin($userId: ID, $typeId: ID!, $days: [TimeOffRequestDayInput!]!, $comments: String, $isApproved: Boolean) {
  createTimeOffRequest(userID: $userId, typeID: $typeId, days: $days, comments: $comments, isApproved: $isApproved) {
    id
    type {
      id
      name
      requireApproval
      isRequestToWork
    }
    created
    comments
    user {
      id
      firstName
      lastName
    }
    days {
      id
      date
      startTime
      endTime
      status
      reviewed {
        timestamp
        comments
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

type CreateRequestResponse = {
  createTimeOffRequest: TimeOffRequest | null;
}

type CreateRequestInput = {
  userId: string;
  typeId: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isAllDay: boolean;
  comments: string;
  isApproved: boolean;
}

export type RequestSort = 'REQUEST_DATE' | 'CREATED_DATE';

export default function TimeOffApproval() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: requestTypeData, loading: requestTypesAreLoading, error: requestTypeError } = useQuery<GetRequestTypesResponse>(GET_REQUEST_TYPES);
  const [loadRequests, { data: requestData, loading: requestsAreLoading, error: requestError }] = useLazyQuery<GetTimeOffRequestsResponse>(GET_ALL_REQUESTS, {
    fetchPolicy: 'network-only',
  });
  const { data: staffData, loading: staffAreLoading, error: staffError } = useQuery<GetAllStaffResponse>(GET_ALL_STAFF, {
    variables: {
      schedules: null,
      includeViewOnly: true,
    },
  });
  const [getUserRequests, { data: userRequestData, loading: userRequestsAreLoading, error: userRequestError }] = useLazyQuery<GetUserTimeOffAndRequestsResponse>(GET_USER_REQUESTS, {
    fetchPolicy: 'network-only',
  });
  const [createRequest] = useMutation<CreateRequestResponse>(CREATE_REQUEST, {
    update(cache) {
      cache.evict({ fieldName: 'timeOffRequests' });
    }
  });
  const [view, setView] = useState<string>('LIST');
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
    endDate: '',
  });
  const [selectedTypes, setSelectedTypes] = useState<{ [key: string]: boolean }>({});
  const [sort, setSort] = useState<RequestSort>('REQUEST_DATE');

  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }

    loadRequests({ variables: { startDate: dateRange.startDate, endDate: dateRange.endDate } });
  }, [dateRange, loadRequests]);

  function getSelectedTypes() {
    return Object.keys(selectedTypes).filter((key) => selectedTypes[key]);
  }

  /* Create Request */
  const handleCreateRequest = async (values: CreateRequestInput) => {
    let days = [];

    let currentDate = new Date(values.startDate);
    while (currentDate <= new Date(values.endDate)) {
      const formattedDate = currentDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })

      if (values.isAllDay) {
        days.push({
          date: formattedDate,
          startTime: '00:00',
          endTime: '23:59',
        });
      } else {
        if (values.startDate === values.endDate) {
          days.push({
            date: formattedDate,
            startTime: values.startTime,
            endTime: values.endTime,
          })
        } else {
          days.push({
            date: formattedDate,
            startTime: formattedDate === values.startDate ? values.startTime : '00:00',
            endTime: formattedDate === values.endDate ? values.endTime : '23:59',
          })
        }
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    const { errors } = await createRequest({
      variables: {
        userId: values.userId,
        typeId: values.typeId,
        comments: values.comments ? values.comments : null,
        days: days,
        isApproved: values.isApproved,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating request' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request created' type='success' id={id} />
      addAlert(id, alert);
    }
  };

  const createRequestModal = (
    <FormModal<CreateRequestInput> title='Create Request' submitLabel='Create' onSubmit={handleCreateRequest} initialValues={{ typeId: '', startDate: '', startTime: '', endDate: '', endTime: '', isAllDay: true, comments: '', isApproved: false, userId: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError, setValue }) => {
          const selectedTypeId = getValue?.('typeId');
          const selectedType = requestTypeData?.timeOffRequestTypes.find((requestType) => requestType.id === selectedTypeId);
          const requireComments = selectedType ? (selectedType?.requireApproval || !selectedType?.isRequestToWork) : false;

          if (staffAreLoading) {
            return (
              <View style={{ alignItems: 'center', gap: '16px' }}>
                <CircularSpinner size='medium' />
              </View>
            );
          }

          return (
            <View style={{ gap: '16px' }}>
              {requestTypesAreLoading ?
                <CircularSpinner size='medium' />
                :
                <>
                  <SingleSelect label='Employee' name='userId' required filterable autoFocusSearch validate={(_, value) => {
                    if (!value) {
                      return null;
                    }

                    getUserRequests({
                      variables: {
                        id: value,
                        startDate: new Date().toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        }),
                        endDate: new Date((new Date().setFullYear(new Date().getFullYear() + 1))).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        }),
                      },
                    });

                    return null;
                  }}>
                    {staffData?.scheduleUsers.map((user) => (
                      <Choice label={`${user.firstName} ${user.lastName}`} value={user.id} key={user.id} />
                    ))}
                  </SingleSelect>

                  {getValue?.('userId') &&
                    (userRequestsAreLoading ?
                      <View style={{ alignItems: 'center', marginTop: '16px' }}>
                        <CircularSpinner size='medium' />
                      </View>
                      :
                      <>
                        <SingleSelect label='Type' name='typeId' required>
                          {requestTypeData?.timeOffRequestTypes.map((requestType) => (
                            <Choice label={requestType.name} description={requestType.description || undefined} value={requestType.id} key={requestType.id} />
                          ))}
                        </SingleSelect>

                        <Checkbox label='All day' name='isAllDay' description='Uncheck to request partial days' validate={(_, value) => {
                          if (value) {
                            setError?.('startTime', null);
                            setError?.('endTime', null);
                          }
                        }} />

                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <DatePicker label='Start Date' name='startDate' required style={{ width: '50%' }} validationDependencies={['endDate']} dateFilter={(date: Date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);

                            let endDate = null;
                            if (getValue?.('endDate')) {
                              endDate = new Date(getValue?.('endDate'));
                            }

                            if (date < today) {
                              return false;
                            }

                            if (endDate && date > endDate) {
                              return false;
                            }

                            // Check for overlapping time off requests
                            if (userRequestData?.scheduleUser?.timeOffRequests) {
                              for (const request of userRequestData?.scheduleUser?.timeOffRequests) {
                                if (request.days.some((day) => day.date === date.toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                }))) {
                                  return false;
                                }
                              }
                            }

                            return true;
                          }} />

                          {!getValue?.('isAllDay') &&
                            <SingleSelect label='Start Time' name='startTime' required={!getValue?.('isAllDay')} style={{ width: '50%' }} filterable autoFocusSearch>
                              {
                                availabilityTimes.map((time) => (
                                  <Choice label={time.label} value={time.value} key={time.value} />
                                ))
                              }
                            </SingleSelect>
                          }
                        </View>

                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <DatePicker label='End Date' name='endDate' required style={{ width: '50%' }} validationDependencies={['startDate']} dateFilter={(date: Date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);

                            let startDate = null;
                            if (getValue?.('startDate')) {
                              startDate = new Date(getValue?.('startDate'));
                            }

                            if (date < today) {
                              return false;
                            }

                            if (startDate && date < startDate) {
                              return false;
                            }

                            // Check for overlapping time off requests
                            if (userRequestData?.scheduleUser?.timeOffRequests) {
                              for (const request of userRequestData?.scheduleUser?.timeOffRequests) {
                                if (request.days.some((day) => day.date === date.toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                }))) {
                                  return false;
                                }
                              }
                            }

                            if (getValue?.('startDate') && userRequestData?.scheduleUser?.timeOffRequests && userRequestData.scheduleUser.timeOffRequests.length > 0) {
                              // Find the earliest existing request start date that is greater than the new start date
                              let selectedStartDate = new Date(getValue?.('startDate'));
                              selectedStartDate.setHours(0, 0, 0, 0);

                              let maxEndDate = null;

                              for (const request of userRequestData.scheduleUser.timeOffRequests) {
                                let startDate = new Date(request.days[0].date);
                                startDate.setHours(0, 0, 0, 0);

                                if (startDate > selectedStartDate) {
                                  if (!maxEndDate || startDate < maxEndDate) {
                                    maxEndDate = startDate;
                                  }
                                }
                              }

                              if (maxEndDate && date >= maxEndDate) {
                                return false;
                              }
                            }

                            return true;
                          }} />

                          {!getValue?.('isAllDay') &&
                            <SingleSelect label='End Time' name='endTime' required={!getValue?.('isAllDay')} style={{ width: '50%' }} filterable autoFocusSearch>
                              {
                                availabilityTimes.map((time) => (
                                  <Choice label={time.label} value={time.value} key={time.value} />
                                ))
                              }
                            </SingleSelect>
                          }
                        </View>

                        <TextArea label='Comments' name='comments' required={requireComments} validationDependencies={[requireComments]} />

                        {(selectedType && (!selectedType?.requireApproval || selectedType?.isRequestToWork)) ?
                          <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>This request will automatically be approved.</StyledParagraph>
                          :
                          <Checkbox label='Approve request' name='isApproved' />
                        }
                      </>
                    )
                  }
                </>
              }
            </View>
          );
        }}
      </FormModalValueProvider>
    </FormModal >
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (requestError || requestTypeError || userRequestError || staffError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  const pendingRequestCount = requestData?.timeOffRequests.filter((request) => request.days.some((day) => day.status === 'PENDING_APPROVAL')).length || 0;

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Time Off & Requests</StyledHeading>
          <ModalLauncher modal={createRequestModal}>
            {({ openModal }) => (
              <Button label='Create Request' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'flex-end', flexDirection: 'row', flexWrap: 'wrap', gap: '32px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '32px', justifyContent: 'flex-start' }}>
            <OptionBar selectedValue={view} onChange={setView}>
              <OptionItem label='List' value='LIST' />
              <OptionItem label='Calendar' value='CALENDAR' />
            </OptionBar>

            <WeekPicker startDayOfWeek='MONDAY' startDate={dateRange.startDate} endDate={dateRange.endDate} onChange={(startDate: string, endDate: string) => setDateRange({ startDate: startDate, endDate: endDate })} />

            {!requestsAreLoading &&
              <StyledParagraph>{pendingRequestCount} pending request{pendingRequestCount === 1 ? '' : 's'}</StyledParagraph>
            }
          </View>

          <View style={{ alignItems: 'flex-end', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start' }}>
            <MultiSelect name='showRequestTypes' value={selectedTypes} placeholder='Filter by type' entityLabel='types' onChange={(_, value) => { setSelectedTypes(value); }} style={{ maxWidth: '250px', minWidth: '250px' }}>
              {requestTypesAreLoading ?
                <View style={{ alignItems: 'center', margin: '16px 0' }}>
                  <CircularSpinner size='small' />
                </View>
                :
                requestTypeData?.timeOffRequestTypes.filter((requestType) => !requestType.isRequestToWork).map((requestType) => {
                  return <Choice key={requestType.id} label={requestType.name} value={requestType.id} />
                })
              }
            </MultiSelect>

            {view === 'LIST' &&
              <SingleSelect label='Sort by' name='sort' value={sort} onChange={(_, value) => { setSort(value as RequestSort); }} style={{ minWidth: '200px', maxWidth: '200px' }}>
                <Choice label='Request date' value='REQUEST_DATE' />
                <Choice label='Created date' value='CREATED_DATE' />
              </SingleSelect>
            }
          </View>
        </View>
      </Cell>
      {view === 'LIST' ?
        <ListView requests={requestData?.timeOffRequests || []} requestsAreLoading={requestsAreLoading} selectedTypes={getSelectedTypes()} startDate={dateRange.startDate} endDate={dateRange.endDate} sort={sort} />
        :
        <CalendarView requests={requestData?.timeOffRequests || []} requestsAreLoading={requestsAreLoading} selectedTypes={getSelectedTypes()} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      }
    </StandardGrid>
  );
}