import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, Colors, ConfirmModal, ErrorPage, FormModal, generateId, HasProductRole, Icon, Icons, ModalLauncher, NoPermission, OptionBar, OptionItem, Products, SchedulingRoles, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextArea, TextField, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useState } from "react";

/* Get Request Types Query */
const GET_ALL_REQUEST_TYPES = gql`
query getAllTimeOffRequestTypes($isArchived: Boolean) {
  timeOffRequestTypes(isArchived: $isArchived) {
    id
    name
    description
    requireApproval
    isRequestToWork
    isArchived
  }
}
`;

type GetAllRequestTypesResponse = {
  timeOffRequestTypes: TimeOffRequestType[];
}

type TimeOffRequestType = {
  id: string;
  name: string;
  description: string | null;
  requireApproval: boolean;
  isRequestToWork: boolean;
  isArchived: boolean;
}

/* Create Request Type Mutation */
const CREATE_REQUEST_TYPE = gql`
mutation createTimeOffRequestType($input: CreateTimeOffRequestTypeInput!) {
  createTimeOffRequestType(input: $input) {
    id
    name
    description
    requireApproval
    isRequestToWork
    isArchived
  }
}
`;

type CreateRequestTypeInput = {
  name: string;
  description: string;
  type: string;
  requireApproval: boolean;
}

/* Edit Request Type Mutation */
const EDIT_REQUEST_TYPE = gql`
mutation editTimeOffRequestType($id: ID!, $input: EditTimeOffRequestTypeInput!) {
  editTimeOffRequestType(id: $id, input: $input) {
    id
    name
    description
    requireApproval
    isRequestToWork
    isArchived
  }
}
`;

type EditRequestTypeInput = {
  requestTypeId: string;
  name: string;
  description: string;
  requireApproval: boolean;
}

/* Archive Request Type Mutation */
const ARCHIVE_REQUEST_TYPE = gql`
mutation archiveTimeOffRequestType($id: ID!) {
  archiveTimeOffRequestType(id: $id) {
    id
    isArchived
  }
}
`;

/* Unarchive Request Type Mutation */
const UNARCHIVE_REQUEST_TYPE = gql`
mutation unarchiveTimeOffRequestType($id: ID!) {
  unarchiveTimeOffRequestType(id: $id) {
    id
    isArchived
  }
}
`;

/* Delete Request Type Mutation */
const DELETE_REQUEST_TYPE = gql`
mutation deleteTimeOffRequestType($id: ID!) {
  success: deleteTimeOffRequestType(id: $id)
}
`;

type DeleteRequestTypeResponse = {
  success: boolean;
}

type DeleteRequestTypeInput = {
  name: string;
}

export default function RequestTypes() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [showArchived, setShowArchived] = useState('FALSE');
  const { data: requestTypeData, loading: requestTypesAreLoading, error: requestTypesError } = useQuery<GetAllRequestTypesResponse>(GET_ALL_REQUEST_TYPES, {
    variables: {
      isArchived: showArchived === 'TRUE',
    },
  })
  const [createRequestType] = useMutation(CREATE_REQUEST_TYPE, {
    refetchQueries: [GET_ALL_REQUEST_TYPES],
  });

  /* Create Request Type */
  const handleCreateRequestType = async (values: CreateRequestTypeInput) => {
    const { errors } = await createRequestType({
      variables: {
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          requireApproval: values.requireApproval,
          isRequestToWork: values.type === 'REQUEST_TO_WORK' ? true : false,
        },
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating request type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request type created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createRequestTypeModal = (
    <FormModal<CreateRequestTypeInput> title='Create Request Type' onSubmit={handleCreateRequestType} submitLabel='Create' initialValues={{ name: '', description: '', requireApproval: true, type: 'TIME_OFF' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Request Type Name' name='name' required />
        <TextArea label='Description' name='description' />
        <SingleSelect label='Type' name='type' required>
          <Choice label='Time Off' value='TIME_OFF' />
          <Choice label='Request To Work' value='REQUEST_TO_WORK' />
        </SingleSelect>
        <Checkbox label='Require manager approval' name='requireApproval' />
      </View>
    </FormModal>
  );

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (requestTypesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Request Types' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', maxWidth: '800px', marginBottom: '16px', '@media (max-width: 470px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Request Types</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <OptionBar selectedValue={showArchived} onChange={setShowArchived}>
              <OptionItem label='Active' value='FALSE' />
              <OptionItem label='Archived' value='TRUE' />
            </OptionBar>

            <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
              <ModalLauncher modal={createRequestTypeModal}>
                {({ openModal }) => (
                  <Button label='Create Request Type' role='button' variant='primary' action={openModal} style={{ maxWidth: 'fit-content' }} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(requestTypesAreLoading) ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ boxSizing: 'border-box', maxWidth: '800px', gap: '16px' }}>
            {(requestTypeData?.timeOffRequestTypes || []).length === 0 ?
              <Card style={{ boxSizing: 'border-box', width: '100%' }}>
                <StyledParagraph>No request types found.</StyledParagraph>
              </Card>
              :
              <>
                {requestTypeData?.timeOffRequestTypes?.map((requestType) => (
                  <RequestTypeCard key={requestType.id} requestType={requestType} />
                ))}
              </>
            }
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

type RequestTypeCardProps = {
  requestType: TimeOffRequestType;
}

function RequestTypeCard(props: RequestTypeCardProps) {
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editRequestType] = useMutation(EDIT_REQUEST_TYPE);
  const [archiveRequestType] = useMutation(ARCHIVE_REQUEST_TYPE, {
    refetchQueries: [{ query: GET_ALL_REQUEST_TYPES, variables: { isArchived: false } }, { query: GET_ALL_REQUEST_TYPES, variables: { isArchived: true } }],
  });
  const [unarchiveRequestType] = useMutation(UNARCHIVE_REQUEST_TYPE, {
    refetchQueries: [{ query: GET_ALL_REQUEST_TYPES, variables: { isArchived: false } }, { query: GET_ALL_REQUEST_TYPES, variables: { isArchived: true } }],
  });
  const [deleteRequestType] = useMutation<DeleteRequestTypeResponse>(DELETE_REQUEST_TYPE, {
    update(cache, { data }) {
      if (!data?.success) {
        return;
      }

      cache.evict({
        id: cache.identify(props.requestType)
      });
    },
  });

  /* Edit Request Type */
  const handleEditRequestType = async (values: EditRequestTypeInput) => {
    const { errors } = await editRequestType({
      variables: {
        id: values.requestTypeId,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          requireApproval: values.requireApproval,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update request type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editRequestTypeModal = (
    <FormModal<EditRequestTypeInput> title='Edit Request Type' onSubmit={handleEditRequestType} initialValues={{ requestTypeId: '', name: '', description: '', requireApproval: true }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Request Type Name' name='name' required />
        <TextField label='Description' name='description' />
        <Checkbox label='Require manager approval' name='requireApproval' />
      </View>
    </FormModal>
  );

  /* Archive Request Type */
  const handleArchiveRequestType = async () => {
    const { errors } = await archiveRequestType({
      variables: {
        id: props.requestType.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to archive request type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request type archived' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const archiveRequestTypeModal = (
    <ConfirmModal title='Archive Request Type?' onConfirm={handleArchiveRequestType} confirmLabel='Archive'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This request type will be archived. You can unarchive it later.</StyledParagraph>
        <StyledParagraph>After archiving, this request type will no longer be available as an option for new requests.</StyledParagraph>
        <StyledParagraph>Requests of this type will still appear on old schedules.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Unarchive Request Type */
  const handleUnarchiveRequestType = async () => {
    const { errors } = await unarchiveRequestType({
      variables: {
        id: props.requestType.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to restore request type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request type restored' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const unarchiveRequestTypeModal = (
    <ConfirmModal title='Restore Request Type?' onConfirm={handleUnarchiveRequestType} confirmLabel='Restore'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>After restoring, this request type will appear as an option for new requests.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Delete Request Type */
  const handleDeleteRequestType = async (values: DeleteRequestTypeInput) => {
    if (values.name !== props.requestType.name) {
      return;
    }

    const { errors } = await deleteRequestType({
      variables: {
        id: props.requestType.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete request type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Request type deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteRequestTypeModal = (
    <FormModal<DeleteRequestTypeInput> title='Delete Request Type?' onSubmit={handleDeleteRequestType} submitLabel='Delete' destructive initialValues={{ name: '' }}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph bold style={{ color: Colors.error500 }}>This request type and all requests associated with it will be permanently deleted.</StyledParagraph>
        <TextField label='Type the name of this request type to proceed' description={`Enter "${props.requestType.name}" below`} name='name' required validate={(_, value: string) => {
          if (value !== props.requestType.name) {
            return 'Name does not match';
          }

          return null;
        }} />
      </View>
    </FormModal>
  );

  return (
    <Card style={{ boxSizing: 'border-box', width: '100%' }}>
      <View style={{ gap: '16px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px', width: 'fit-content' }} onClick={() => { setIsOpen(!isOpen); }}>
            {isOpen ? <Icon icon={Icons.ChevronDown} size='small' key='icon-open' /> : <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />}
            <StyledHeading tag='h6' style={{ 'user-select': 'none' }}>{props.requestType.name}</StyledHeading>
          </View>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <ModalLauncher modal={editRequestTypeModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={archiveRequestTypeModal}>
                  {({ openModal: openArchiveModal }) => (
                    <ModalLauncher modal={unarchiveRequestTypeModal}>
                      {({ openModal: openUnarchiveModal }) => (
                        <ModalLauncher modal={deleteRequestTypeModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu>
                              {!props.requestType.isArchived && <ActionItem label='Edit' onClick={() => { openEditModal({ requestTypeId: props.requestType.id, name: props.requestType.name, description: props.requestType.description, requireApproval: props.requestType.requireApproval }); }} />}
                              {!props.requestType.isArchived && <ActionItem label='Archive' onClick={openArchiveModal} />}
                              {props.requestType.isArchived && <ActionItem label='Restore' onClick={openUnarchiveModal} />}
                              {props.requestType.isArchived && <ActionItem label='Delete' onClick={openDeleteModal} />}
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>

        {isOpen &&
          <View style={{ marginLeft: '32px', gap: '16px' }}>
            <View>
              <StyledParagraph bold>Description</StyledParagraph>
              <StyledParagraph>{props.requestType.description ? props.requestType.description : '-----'}</StyledParagraph>
            </View>
            <View>
              <StyledParagraph bold>Manager Approval Required</StyledParagraph>
              <StyledParagraph>{props.requestType.requireApproval ? 'Yes' : 'No'}</StyledParagraph>
            </View>
            <View>
              <StyledParagraph bold>Type</StyledParagraph>
              <StyledParagraph>{props.requestType.isRequestToWork ? 'Request To Work' : 'Time Off'}</StyledParagraph>
            </View>
          </View>
        }
      </View>
    </Card>
  );
}