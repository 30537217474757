import { Card, Cell, Colors, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";
import { css, CSSProperties, StyleSheet } from "aphrodite";
import { Link } from "react-router-dom";

export default function Settings() {
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '48px' }}>
          <View>
            <StyledHeading tag='h4'>Scheduling</StyledHeading>

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', paddingTop: '16px', width: '100%' }}>
              <SettingsCard
                title='Request Types'
                description='Create different types of time off requests'
                iconID='fa-calendar-xmark'
                href='/settings/request-types'
              />

              <SettingsCard
                title='Labor Categories'
                description='Labor categories define groups of related jobs for reports'
                iconID='fa-user-group'
                href='/settings/labor-categories'
              />

              <SettingsCard
                title='Jobs'
                description='Jobs define different roles your employees may perform'
                iconID='fa-user-tie'
                href='/settings/jobs'
              />

              <SettingsCard
                title='Stations'
                description='Stations define the work areas of your employees'
                iconID='fa-location-dot'
                href='/settings/stations'
              />

              <SettingsCard
                title='Schedules'
                description='Schedules define collections of shifts that can be managed and published separately'
                iconID='fa-calendar'
                href='/settings/schedules'
              />

              <SettingsCard
                title='Advanced Settings'
                description='Customize your schedule configuration'
                iconID='fa-gear'
                href='/settings/advanced'
              />
            </View>
          </View>
        </View>
      </Cell>
    </StandardGrid>
  );
}

type SettingsCardProps = {
  title: string;
  description: string;
  iconID: string;
  href: string;
}

const styles = StyleSheet.create({
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
    height: '110px',
    width: 'calc((100% - 32px) / 3)',
    '@media (min-width: 768px) and (max-width: 1460px)': {
      width: 'calc((100% - 32px) / 2)'
    },
    '@media (max-width: 767px)': {
      width: '100%'
    },
  },
});

const cardStyle: CSSProperties = {
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral300,
  },
}

function SettingsCard(props: SettingsCardProps) {
  return (
    <Link to={props.href} className={css(styles.cardLink)}>
      <Card size='medium' style={cardStyle}>
        <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px' }}>
          <i className={"fa-sharp fa-regular fa-xl " + props.iconID}></i>
          <View style={{ gap: '4px' }}>
            <StyledHeading tag='h6'>{props.title}</StyledHeading>
            <StyledParagraph style={{ color: Colors.neutral700 }}>{props.description}</StyledParagraph>
          </View>
        </View>
      </Card>
    </Link>
  );
}