import { generateId, StandardAlert, useAlertState } from "@barscience/global-components";
import { gql, useMutation } from "@apollo/client";
import { Availability, AvailabilityInput } from "./availabilityUtils";
import { GET_USER_AVAILABILITIES } from "../profilePages/StaffAvailabilityPage";
import AvailabilityEditor from "./AvailabilityEditor";

/* Create Availability Mutation */
const CREATE_AVAILABILITY = gql`
mutation createScheduleAvailability(
  $input: CreateScheduleAvailabilityInput!
  $userId: ID
) {
  createScheduleAvailability(input: $input, userID: $userId) {
    id
    startDate
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      comments
      timestamp
    }
    minShiftsPerWeek
    maxShiftsPerWeek
    minHoursPerWeek
    maxHoursPerWeek
    created
    availability {
      monday {
        startTime
        endTime
        isAvailable
        reason
      }
      tuesday {
        startTime
        endTime
        isAvailable
        reason
      }
      wednesday {
        startTime
        endTime
        isAvailable
        reason
      }
      thursday {
        startTime
        endTime
        isAvailable
        reason
      }
      friday {
        startTime
        endTime
        isAvailable
        reason
      }
      saturday {
        startTime
        endTime
        isAvailable
        reason
      }
      sunday {
        startTime
        endTime
        isAvailable
        reason
      }
    }
  }
}
`;

type CreateAvailabilityResponse = {
  createScheduleAvailability: Availability;
}

type CreateAvailabilityModalProps = {
  userID: string;
  handleClose?: () => void;
}

export default function CreateAvailabilityModal(props: CreateAvailabilityModalProps) {
  const { addAlert } = useAlertState();
  const [createAvailability, { loading, error }] = useMutation<CreateAvailabilityResponse>(CREATE_AVAILABILITY, {
    refetchQueries: [{ query: GET_USER_AVAILABILITIES, variables: { id: props.userID, includeUnapproved: true } }],
    errorPolicy: 'all',
  });

  const handleSubmit = async (values: AvailabilityInput) => {
    const { errors } = await createAvailability({
      variables: {
        userId: props.userID,
        input: {
          startDate: values.startDate,
          minShiftsPerWeek: values.minShiftsPerWeek,
          maxShiftsPerWeek: values.maxShiftsPerWeek,
          minHoursPerWeek: values.minHoursPerWeek,
          maxHoursPerWeek: values.maxHoursPerWeek,
          monday: values.monday,
          tuesday: values.tuesday,
          wednesday: values.wednesday,
          thursday: values.thursday,
          friday: values.friday,
          saturday: values.saturday,
          sunday: values.sunday,
        },
      },
    });

    if (!errors) {
      props.handleClose && props.handleClose();

      const id = generateId();
      const alert = <StandardAlert title='Availability created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  return (
    <AvailabilityEditor 
      modalTitle='Create Availability'
      submitLabel='Create Availability'
      isLoading={loading}
      onSubmit={handleSubmit}
      error={error ? error.message : null}
      handleClose={props.handleClose ? props.handleClose : () => {}}
    />
  );
}