import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, CircularSpinner, Colors, ErrorPage, FormModal, generateId, Icon, Icons, ModalLauncher, NoPermission, Products, SchedulingRoles, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, Tooltip, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { AvailabilityWithUser } from "../staff/availabilities/availabilityUtils";
import { APPROVE_AVAILABILITY, DECLINE_AVAILABILITY } from "../staff/profilePages/StaffAvailabilityPage";
import AvailabilityDetails from "../staff/availabilities/AvailabilityDetails";

/* Get User Availabilities Query */
const GET_PENDING_AVAILABILITIES = gql`
query getAllPendingScheduleAvailabilities {
  pendingScheduleAvailabilities {
    id
    startDate
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      comments
      timestamp
    }
    minShiftsPerWeek
    maxShiftsPerWeek
    minHoursPerWeek
    maxHoursPerWeek
    created
    availability {
      monday {
        startTime
        endTime
        isAvailable
        reason
      }
      tuesday {
        startTime
        endTime
        isAvailable
        reason
      }
      wednesday {
        startTime
        endTime
        isAvailable
        reason
      }
      thursday {
        startTime
        endTime
        isAvailable
        reason
      }
      friday {
        startTime
        endTime
        isAvailable
        reason
      }
      saturday {
        startTime
        endTime
        isAvailable
        reason
      }
      sunday {
        startTime
        endTime
        isAvailable
        reason
      }
    }
    user {
      id
      firstName
      lastName
    }
  }
}
`;

type GetPendingUserAvailabilitiesResponse = {
  pendingScheduleAvailabilities: AvailabilityWithUser[];
}

/* Get Previous Availability Query */
const GET_PREVIOUS_AVAILABILITY = gql`
query getPreviousAvailability($id: ID!) {
  previousAvailability(id: $id) {
    id
    startDate
    status
    reviewed {
      reviewer {
        id
        firstName
        lastName
      }
      comments
      timestamp
    }
    minShiftsPerWeek
    maxShiftsPerWeek
    minHoursPerWeek
    maxHoursPerWeek
    created
    availability {
      monday {
        startTime
        endTime
        isAvailable
        reason
      }
      tuesday {
        startTime
        endTime
        isAvailable
        reason
      }
      wednesday {
        startTime
        endTime
        isAvailable
        reason
      }
      thursday {
        startTime
        endTime
        isAvailable
        reason
      }
      friday {
        startTime
        endTime
        isAvailable
        reason
      }
      saturday {
        startTime
        endTime
        isAvailable
        reason
      }
      sunday {
        startTime
        endTime
        isAvailable
        reason
      }
    }
    user {
      id
      firstName
      lastName
    }
  }
}
`;

type GetPreviousUserAvailabilityResponse = {
  previousAvailability: AvailabilityWithUser;
}

/* Page Types */
type ApproveAvailabilityInput = {
  comments: string;
}

type DeclineAvailabilityInput = {
  id: string;
  comments: string;
}

export default function AvailabilityApproval() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [selectedAvailabilityId, setSelectedAvailabilityId] = useState<string | null>(null);
  const [previousIsOpen, setPreviousIsOpen] = useState<boolean>(false);
  const { data, loading, error } = useQuery<GetPendingUserAvailabilitiesResponse>(GET_PENDING_AVAILABILITIES, {
    onCompleted: (data) => {
      if (data?.pendingScheduleAvailabilities.length > 0) {
        setSelectedAvailabilityId(data.pendingScheduleAvailabilities[0].id);
      }
    }
  });
  const [getPreviousAvailability, { data: previousData, loading: previousLoading, error: previousError }] = useLazyQuery<GetPreviousUserAvailabilityResponse>(GET_PREVIOUS_AVAILABILITY);
  const [approveAvailability] = useMutation(APPROVE_AVAILABILITY);
  const [declineAvailability] = useMutation(DECLINE_AVAILABILITY);

  useEffect(() => {
    if (selectedAvailabilityId) {
      getPreviousAvailability({
        variables: {
          id: selectedAvailabilityId,
        },
      });
    }
  }, [selectedAvailabilityId, getPreviousAvailability]);

  /* Approve Availability */
  const handleApproveAvailability = async (values: ApproveAvailabilityInput) => {
    const { errors } = await approveAvailability({
      variables: {
        id: selectedAvailabilityId!,
        comments: values.comments ? values.comments : null,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to approve availability' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const approveModal = (
    <FormModal<ApproveAvailabilityInput> title='Approve Availability' submitLabel='Approve' initialValues={{ comments: '' }} onSubmit={handleApproveAvailability}>
      <TextArea label='Comments' name='comments' />
    </FormModal>
  );

  /* Decline Availability */
  const handleDeclineAvailability = async (values: DeclineAvailabilityInput) => {
    const { errors } = await declineAvailability({
      variables: {
        id: values.id,
        comments: values.comments,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to decline availability' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const declineModal = (
    <FormModal<DeclineAvailabilityInput> title='Decline Availability' submitLabel='Decline' initialValues={{ id: '', comments: '' }} onSubmit={handleDeclineAvailability} destructive>
      <TextArea label='Comments' name='comments' required />
    </FormModal>
  );

  const selectedAvailability = data?.pendingScheduleAvailabilities.find((availability) => availability.id === selectedAvailabilityId);

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (error || previousError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (loading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!loading && data?.pendingScheduleAvailabilities.length === 0) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <StyledHeading tag='h3'>Availability Approval</StyledHeading>
        </Cell>
        <Cell lg={12} md={8} sm={4}>
          <View style={{ alignItems: 'center', gap: '16px', justifyContent: 'center', marginTop: '15%', width: '100%' }}>
            <Icon icon={Icons.CircleCheckmark} size='large' style={{ color: Colors.primary500, height: '100px', width: '100px' }} />
            <StyledHeading tag='h5' style={{ textAlign: 'center' }}>There are no availabilities to review</StyledHeading>
          </View>
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <View style={{ backgroundColor: Colors.neutral50, boxSizing: 'border-box', display: 'flex', gap: '24px', maxHeight: '100%', overflow: 'auto', padding: '24px' }}>
      <StyledHeading tag='h3'>Availability Approval</StyledHeading>
      <Card style={{ boxSizing: 'border-box', display: 'flex', flexGrow: 1, padding: '0' }}>
        <View style={{ flexGrow: 1 }}>
          <View style={{ flexDirection: 'row', flexGrow: 1 }}>
            <View style={{ alignItems: 'center', borderRight: `1px solid ${Colors.neutral300}`, flexGrow: 1, width: '275px' }}>
              {data?.pendingScheduleAvailabilities.map((availability) => {
                const isSelected = availability.id === selectedAvailabilityId;

                return (
                  <View key={availability.id} style={{ borderBottom: `1px solid ${Colors.neutral300}`, cursor: 'pointer', padding: '16px', width: '100%', ...(isSelected ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => setSelectedAvailabilityId(availability.id)}>
                    <StyledParagraph bold>{availability.user.firstName} {availability.user.lastName}</StyledParagraph>
                    <StyledParagraph bold>Starting on {availability.startDate}</StyledParagraph>
                    {availability.status === 'APPROVED' &&
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
                        <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
                      </View>
                    }
                    {availability.status === 'DECLINED' &&
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
                        <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
                      </View>
                    }
                  </View>
                );
              })}
            </View>
            <View style={{ width: '100%' }}>
              <View style={{ padding: '16px', flexGrow: 1 }}>
                {selectedAvailability ?
                  <View style={{ gap: '64px', justifyContent: 'space-between', minHeight: 'fit-content', overflowY: 'auto' }}>
                    <View style={{ gap: '64px', justifyContent: 'space-between', minHeight: 'fit-content' }}>
                      <View style={{ gap: '48px', flexDirection: 'row', flexWrap: 'wrap', minHeight: 'fit-content', '@media (max-width: 890px)': { flexDirection: 'column' } }}>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Monday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.monday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Tuesday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.tuesday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Wednesday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.wednesday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Thursday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.thursday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Friday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.friday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Saturday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.saturday} />
                        </View>
                        <View style={{ gap: '8px' }}>
                          <StyledHeading tag='h6'>Sunday</StyledHeading>
                          <AvailabilityDetails availability={selectedAvailability.availability.sunday} />
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '48px' }}>
                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <View>
                            <StyledCaption>Min. shifts per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.minShiftsPerWeek}</StyledParagraph>
                          </View>
                          <View>
                            <StyledCaption>Max. shifts per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.maxShiftsPerWeek}</StyledParagraph>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <View>
                            <StyledCaption>Min. hours per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.minHoursPerWeek}</StyledParagraph>
                          </View>
                          <View>
                            <StyledCaption>Max. hours per week</StyledCaption>
                            <StyledParagraph style={{ fontSize: '18px' }} bold>{selectedAvailability.maxHoursPerWeek}</StyledParagraph>
                          </View>
                        </View>
                      </View>

                      <View style={{ border: `1px solid ${Colors.neutral300}`, borderRadius: '8px', gap: '32px', minHeight: 'fit-content', padding: '16px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '8px' }} onClick={() => setPreviousIsOpen(!previousIsOpen)}>
                            {previousIsOpen ?
                              <Icon icon={Icons.ChevronDown} size='small' key='icon-open' />
                              :
                              <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />
                            }

                            <StyledHeading tag='h5'>Previous Availability</StyledHeading>
                          </View>

                          {previousData?.previousAvailability.status === 'APPROVED' &&
                            <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                              <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
                              <Tooltip content={
                                <View style={{ gap: '16px' }}>
                                  <StyledParagraph>Submitted {new Date(previousData.previousAvailability.created).toLocaleString()}</StyledParagraph>
                                  <StyledParagraph>{previousData.previousAvailability.reviewed?.reviewer.firstName} {previousData.previousAvailability.reviewed?.reviewer.lastName} approved {new Date(previousData.previousAvailability.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                                  {previousData.previousAvailability.reviewed?.comments && <StyledParagraph><span style={{ fontWeight: 'bold' }}>Comments: </span>{previousData.previousAvailability.reviewed?.comments}</StyledParagraph>}
                                </View>
                              }>
                                <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
                              </Tooltip>
                            </View>
                          }

                          {previousData?.previousAvailability.status === 'PENDING_APPROVAL' &&
                            <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                              <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.warning500 }} />
                              <Tooltip content={
                                <View style={{ gap: '16px' }}>
                                  <StyledParagraph>Submitted {new Date(previousData.previousAvailability.created).toLocaleString()}</StyledParagraph>
                                </View>
                              }>
                                <StyledParagraph bold style={{ color: Colors.warning500 }}>Pending Approval</StyledParagraph>
                              </Tooltip>
                            </View>
                          }
                        </View>

                        {previousIsOpen &&
                          <View style={{ minHeight: 'fit-content' }}>
                            {previousLoading ?
                              <View style={{ alignItems: 'center', padding: '24px' }}>
                                <CircularSpinner size='medium' />
                              </View>
                              :
                              <View style={{ gap: '64px' }}>
                                <View style={{ gap: '48px', flexDirection: 'row', flexWrap: 'wrap', minHeight: 'fit-content', '@media (max-width: 890px)': { flexDirection: 'column' } }}>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Monday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.monday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Tuesday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.tuesday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Wednesday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.wednesday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Thursday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.thursday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Friday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.friday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Saturday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.saturday || []} />
                                  </View>
                                  <View style={{ gap: '8px' }}>
                                    <StyledHeading tag='h6'>Sunday</StyledHeading>
                                    <AvailabilityDetails availability={previousData?.previousAvailability.availability.sunday || []} />
                                  </View>
                                </View>

                                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '48px' }}>
                                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                                    <View>
                                      <StyledCaption>Min. shifts per week</StyledCaption>
                                      <StyledParagraph style={{ fontSize: '18px' }} bold>{previousData?.previousAvailability.minShiftsPerWeek}</StyledParagraph>
                                    </View>
                                    <View>
                                      <StyledCaption>Max. shifts per week</StyledCaption>
                                      <StyledParagraph style={{ fontSize: '18px' }} bold>{previousData?.previousAvailability.maxShiftsPerWeek}</StyledParagraph>
                                    </View>
                                  </View>
                                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                                    <View>
                                      <StyledCaption>Min. hours per week</StyledCaption>
                                      <StyledParagraph style={{ fontSize: '18px' }} bold>{previousData?.previousAvailability.minHoursPerWeek}</StyledParagraph>
                                    </View>
                                    <View>
                                      <StyledCaption>Max. hours per week</StyledCaption>
                                      <StyledParagraph style={{ fontSize: '18px' }} bold>{previousData?.previousAvailability.maxHoursPerWeek}</StyledParagraph>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            }
                          </View>
                        }
                      </View>
                    </View>
                  </View>
                  :
                  <View style={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                    <StyledParagraph style={{ color: Colors.neutral700 }}>Select an availability to view details.</StyledParagraph>
                  </View>
                }

              </View>
              {selectedAvailability &&
                <View style={{ borderTop: `1px solid ${Colors.neutral300}`, maxHeight: 'fit-content', minHeight: 'fit-content', padding: '16px', width: '100%' }}>
                  {selectedAvailability?.status === 'APPROVED' &&
                    <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
                        <Tooltip content={
                          <View style={{ gap: '16px' }}>
                            <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                            <StyledParagraph>{selectedAvailability.reviewed?.reviewer.firstName} {selectedAvailability.reviewed?.reviewer.lastName} approved {new Date(selectedAvailability.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                            {selectedAvailability.reviewed?.comments && <StyledParagraph><span style={{ fontWeight: 'bold' }}>Comments: </span>{selectedAvailability.reviewed?.comments}</StyledParagraph>}
                          </View>
                        }>
                          <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
                        </Tooltip>
                      </View>
                    </View>
                  }

                  {selectedAvailability?.status === 'PENDING_APPROVAL' &&
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between', minHeight: 'fit-content' }}>
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.warning500 }} />
                        <Tooltip content={
                          <View style={{ gap: '16px' }}>
                            <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                          </View>
                        }>
                          <StyledParagraph bold style={{ color: Colors.warning500 }}>Pending Approval</StyledParagraph>
                        </Tooltip>
                      </View>

                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '40px' }}>
                        {(state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin || state.user?.roles[Products.Scheduling] === SchedulingRoles.Manager) &&
                          <ModalLauncher modal={approveModal}>
                            {({ openModal: openApproveModal }) => (
                              <ModalLauncher modal={declineModal}>
                                {({ openModal: openDeclineModal }) => (
                                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                                    <Button label='Approve' variant='primary' role='button' action={openApproveModal} />
                                    <Button label='Decline' variant='secondary' role='button' action={() => { openDeclineModal({ id: selectedAvailability.id, comments: '' }); }} destructive />
                                  </View>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        }
                      </View>
                    </View>
                  }

                  {selectedAvailability?.status === 'DECLINED' &&
                    <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                      <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
                      <Tooltip content={
                        <View style={{ gap: '16px' }}>
                          <StyledParagraph>Submitted {new Date(selectedAvailability.created).toLocaleString()}</StyledParagraph>
                          <StyledParagraph>{selectedAvailability.reviewed?.reviewer.firstName} {selectedAvailability.reviewed?.reviewer.lastName} declined {new Date(selectedAvailability.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                          {selectedAvailability.reviewed?.comments && <StyledParagraph><span style={{ fontWeight: 'bold' }}>Comments: </span>{selectedAvailability.reviewed?.comments}</StyledParagraph>}
                        </View>
                      }>
                        <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
                      </Tooltip>
                    </View>
                  }
                </View>
              }
            </View>
          </View>
        </View>
      </Card>
    </View>
  );
}