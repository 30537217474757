import { Card, Cell, CircularSpinner, Colors, Icon, Icons, ModalLauncher, StyledHeading, StyledParagraph, Tooltip, View } from "@barscience/global-components";
import { RequestSort, TimeOffRequest } from "./TimeOffApproval";
import { formatTime } from "./utils";
import ReviewRequestModal from "./ReviewRequestModal";

type ListViewProps = {
  requests: TimeOffRequest[];
  requestsAreLoading: boolean;
  selectedTypes: string[];
  startDate: string;
  endDate: string;
  sort: RequestSort;
}

export default function ListView(props: ListViewProps) {
  if (props.requestsAreLoading) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
          <CircularSpinner size='large' />
        </View>
      </Cell>
    );
  }

  let requests = props.requests.filter((request) => {
    if (request.type.isRequestToWork) {
      return false;
    }

    if (request.days.every((day) => day.status !== 'PENDING_APPROVAL')) {
      return false;
    }

    return true;
  });

  // Hide requests that don't match the selected types
  if (props.selectedTypes.length > 0) {
    requests = requests.filter((request) => props.selectedTypes.includes(request.type.name));
  }

  // Sort the requests to the specified sort order
  if (props.sort === 'CREATED_DATE') {
    requests = requests.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
  } else if (props.sort === 'REQUEST_DATE') {
    requests = requests.sort((a, b) => new Date(a.days[0].date).getTime() - new Date(b.days[0].date).getTime());
  }

  return (
    <Cell lg={12} md={8} sm={4}>
      <View style={{ gap: '16px', maxWidth: '800px' }}>
        {requests.map((request) => {
          return (
            <ModalLauncher modal={<ReviewRequestModal request={request} />} key={request.id}>
              {({ openModal }) => (
                <View onClick={openModal}>
                  <Card style={{ cursor: 'pointer', ':hover': { backgroundColor: Colors.neutral50 }, ':active': { backgroundColor: Colors.neutral100 } }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }}>
                      <View style={{ gap: '4px' }}>
                        <StyledParagraph bold style={{ fontSize: '18px' }}>{request.user.firstName} {request.user.lastName}</StyledParagraph>
                        <StyledParagraph style={{ fontSize: '18px' }}>{getRequestDateLabel(request.days)}</StyledParagraph>
                        <StyledParagraph style={{ color: Colors.neutral700 }}>{request.type.name} {request.type.isRequestToWork ? <span style={{ color: Colors.primary500 }}>(Request to work)</span> : ''}</StyledParagraph>
                      </View>

                      {getStatusLabel(request.days)}
                    </View>
                  </Card>
                </View>
              )}
            </ModalLauncher>
          );
        })}
      </View>
      
      {requests.length === 0 && <View style={{ alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}><StyledHeading tag='h6' style={{ color: Colors.neutral700, textAlign: 'center' }}>No requests for this week.</StyledHeading></View>}
    </Cell>
  );
}

const getStatusLabel = (days: TimeOffRequest['days']) => {
  let isAllApproved = true;
  let isAllDeclined = true;
  let isAllPending = true;

  for (let day of days) {
    if (day.status !== 'APPROVED') {
      isAllApproved = false;
    }

    if (day.status !== 'DECLINED') {
      isAllDeclined = false;
    }

    if (day.status !== 'PENDING_APPROVAL') {
      isAllPending = false;
    }
  }

  if (isAllApproved) {
    let maxApprover = `${days[0].reviewed?.user?.firstName} ${days[0].reviewed?.user?.lastName}`;
    let maxApproveTime = new Date(days[0].reviewed?.timestamp || '');
    for (let day of days) {
      if (new Date(day.reviewed?.timestamp || '') > maxApproveTime) {
        maxApproveTime = new Date(day.reviewed?.timestamp || '');
        maxApprover = `${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`;
      }
    }

    if (!days[0].reviewed?.user) {
      maxApprover = 'Automatic Approval';
    }

    return (
      <Tooltip content={
        <View>
          <StyledParagraph>Approved {maxApproveTime.toLocaleString()} by {maxApprover}</StyledParagraph>
        </View>
      }>
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
          <StyledParagraph bold style={{ color: Colors.primary500 }}>Approved</StyledParagraph>
        </View>
      </Tooltip>
    );
  } else if (isAllDeclined) {
    let maxApprover = `${days[0].reviewed?.user?.firstName} ${days[0].reviewed?.user?.lastName}`;
    let maxApproveTime = new Date(days[0].reviewed?.timestamp || '');
    for (let day of days) {
      if (new Date(day.reviewed?.timestamp || '') > maxApproveTime) {
        maxApproveTime = new Date(day.reviewed?.timestamp || '');
        maxApprover = `${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}`;
      }
    }

    return (
      <Tooltip content={
        <View>
          <StyledParagraph>Declined {maxApproveTime.toLocaleString()} by {maxApprover}</StyledParagraph>
        </View>
      }>
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
          <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
        </View>
      </Tooltip>
    );
  } else if (isAllPending) {
    return (
      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
        <StyledParagraph bold style={{ color: Colors.warning500 }}>Pending approval</StyledParagraph>
      </View>
    );
  } else {
    return (
      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
        <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.secondary500 }} />
        <StyledParagraph bold style={{ color: Colors.secondary500 }}>Partially reviewed</StyledParagraph>
      </View>
    );
  }
}

const getRequestDateLabel = (days: TimeOffRequest['days']): string => {
  if (days.length === 1) {
    if (days[0].startTime === '00:00' && days[0].endTime === '23:59') {
      return `${days[0].date} (All day)`;
    } else {
      return `${days[0].date} (${formatTime(days[0].startTime)} - ${formatTime(days[0].endTime)})`;
    }
  } else {
    return `${days[0].date} ${days[0].startTime !== '00:00' ? `(${formatTime(days[0].startTime)})` : ''} - ${days[days.length - 1].date} ${days[days.length - 1].endTime !== '23:59' ? `(${formatTime(days[days.length - 1].endTime)})` : ''}`;
  }
}