import { Button, Choice, Colors, Icons, SingleSelect, StyledHeading, StyledParagraph, TextField, View } from "@barscience/global-components";
import { AvailabilityRange, DayOfWeek, getDayLabel, hasTimeOverlap } from "./availabilityUtils";
import { availabilityTimes } from "./availabilityTimes";
import { AvailabilityFormInput } from "./AvailabilityEditor";

type AvailabilityDayEditorProps = {
  day: DayOfWeek;
  handleDayAvailabilityChange: (name: string, value: string) => null
  values: AvailabilityFormInput;
  errors: {
    [name: string]: string;
  };
  customValues: {
    monday: AvailabilityRange[];
    tuesday: AvailabilityRange[];
    wednesday: AvailabilityRange[];
    thursday: AvailabilityRange[];
    friday: AvailabilityRange[];
    saturday: AvailabilityRange[];
    sunday: AvailabilityRange[];
  };
  handleChange: (name: string, value: string | number | boolean | {
    [name: string]: boolean;
  } | File | null) => void;
  handleValidate: (name: string, error: string | null) => void;
  setCustomValues: React.Dispatch<React.SetStateAction<{
    monday: AvailabilityRange[];
    tuesday: AvailabilityRange[];
    wednesday: AvailabilityRange[];
    thursday: AvailabilityRange[];
    friday: AvailabilityRange[];
    saturday: AvailabilityRange[];
    sunday: AvailabilityRange[];
  }>>
}

export default function AvailabilityDayEditor(props: AvailabilityDayEditorProps) {
  return (
    <View style={{ gap: '16px', minHeight: 'fit-content' }}>
      <StyledHeading tag='h5'>{getDayLabel(props.day)}</StyledHeading>
      <View style={{ gap: '16px' }}>
        <SingleSelect name={`${props.day}Availability`} label='' style={{ maxWidth: '250px' }} required validate={props.handleDayAvailabilityChange} value={props.values[`${props.day}Availability`]} onChange={props.handleChange} onValidate={props.handleValidate} error={props.errors[`${props.day}Availability`]}>
          <Choice label='Available all day' value='AVAILABLE' />
          <Choice label='Partially available' value='PARTIALLY_AVAILABLE' />
          <Choice label='Unavailable all day' value='UNAVAILABLE' />
        </SingleSelect>

        {props.values[`${props.day}Availability`] === 'PARTIALLY_AVAILABLE' &&
          <View style={{ minHeight: 'fit-content' }}>
            <View style={{ flexDirection: 'row', gap: '16px', minHeight: 'fit-content' }}>
              <SingleSelect name={`${props.day}PartialAvailabilityType`} label='' required style={{ maxWidth: '250px' }} value={props.values[`${props.day}PartialAvailabilityType`]} validate={(_, value) => {
                if (value === 'CUSTOM') {
                  props.handleValidate(`${props.day}AvailabilityReason`, null);
                }

                return null;
              }} onChange={props.handleChange} onValidate={props.handleValidate} error={props.errors[`${props.day}PartialAvailabilityType`]}>
                <Choice label='Available before' value='BEFORE' />
                <Choice label='Available after' value='AFTER' />
                <Choice label='Custom' value='CUSTOM' />
              </SingleSelect>

              {props.values[`${props.day}PartialAvailabilityType`] !== 'CUSTOM' &&
                <SingleSelect name={`${props.day}PartialAvailabilityTime`} filterable autoFocusSearch required value={props.values[`${props.day}PartialAvailabilityTime`]} onChange={props.handleChange} onValidate={props.handleValidate} error={props.errors[`${props.day}PartialAvailabilityTime`]}>
                  {availabilityTimes.map((t) => (
                    <Choice label={t.label} value={t.value} key={t.value} />
                  ))}
                </SingleSelect>
              }
            </View>

            {props.values[`${props.day}PartialAvailabilityType`] === 'CUSTOM' &&
              <View style={{ gap: '16px', marginTop: '24px', minHeight: 'fit-content' }}>
                <StyledHeading tag='h6' style={{ fontSize: '18px' }}>Unavailable Times</StyledHeading>

                <View style={{ gap: '40px' }}>
                  {props.customValues[props.day].map((timeRange, index) => {
                    const startTimeIndex = availabilityTimes.findIndex((t) => t.value === timeRange.startTime);
                    const endTimeIndex = availabilityTimes.findIndex((t) => t.value === timeRange.endTime);

                    return (
                      <View style={{ gap: '16px' }} key={index}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                          <SingleSelect name='' value={timeRange.startTime} filterable autoFocusSearch placeholder='Select time' onChange={(_, value: string | null) => { props.setCustomValues({ ...props.customValues, [props.day]: props.customValues[props.day].map((t, i) => i === index ? { ...t, startTime: value || '', endTime: props.customValues[props.day][index].endTime } : t) }); }}>
                            {availabilityTimes.map((t) => (
                              <Choice label={t.label} value={t.value} key={t.value} />
                            ))}
                          </SingleSelect>

                          <StyledParagraph>to</StyledParagraph>

                          <SingleSelect name='' value={timeRange.endTime} filterable autoFocusSearch placeholder='Select time' onChange={(_, value: string | null) => { props.setCustomValues({ ...props.customValues, [props.day]: props.customValues[props.day].map((t, i) => i === index ? { ...t, startTime: props.customValues[props.day][index].startTime, endTime: value || '' } : t) }); }}>
                            {availabilityTimes.map((t) => (
                              <Choice label={t.label} value={t.value} key={t.value} />
                            ))}
                          </SingleSelect>

                          <Button label='Remove' role='button' variant='tertiary' destructive action={() => { props.setCustomValues({ ...props.customValues, [props.day]: props.customValues[props.day].filter((_, i) => i !== index) }); }} disabled={props.customValues[props.day].length === 1} />
                        </View>

                        {(startTimeIndex >= endTimeIndex) && <StyledParagraph style={{ color: Colors.error500 }}>End time must be after start time</StyledParagraph>}
                        {hasTimeOverlap(props.customValues[props.day], timeRange.startTime, timeRange.endTime) && <StyledParagraph style={{ color: Colors.error500 }}>Unavailable time ranges cannot overlap</StyledParagraph>}

                        <TextField label='Reason' name='' required value={timeRange.reason || ''} onChange={(_, value) => { props.setCustomValues({ ...props.customValues, [props.day]: props.customValues[props.day].map((t, i) => i === index ? { ...t, reason: value || '' } : t) }); }} error={props.customValues[props.day][index].reason ? null : 'This field is required'} />
                      </View>
                    );
                  })}
                </View>

                <Button label='Add unavailable time' role='button' action={() => { props.setCustomValues({ ...props.customValues, [props.day]: [...props.customValues[props.day], { startTime: '', endTime: '', isAvailable: false, reason: '' }] }); }} variant='tertiary' leftIcon={Icons.Plus} style={{ maxWidth: 'fit-content' }} />
              </View>
            }
          </View>
        }

        {(props.values[`${props.day}Availability`] === 'UNAVAILABLE' || (props.values[`${props.day}Availability`] === 'PARTIALLY_AVAILABLE' && (props.values[`${props.day}PartialAvailabilityType`] === 'AFTER' || props.values[`${props.day}PartialAvailabilityType`] === 'BEFORE'))) &&
          <TextField name={`${props.day}AvailabilityReason`} label='Reason' required value={props.values[`${props.day}AvailabilityReason`]} onChange={props.handleChange} onValidate={props.handleValidate} error={props.errors[`${props.day}AvailabilityReason`]} />
        }
      </View>
    </View>
  );
}