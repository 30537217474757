import { Colors, Icon, Icons, StyledParagraph, View } from "@barscience/global-components";
import { AvailabilityRange } from "./availabilityUtils";

type AvailabilityDetailsProps = {
  availability: AvailabilityRange[];
}

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export default function AvailabilityDetails(props: AvailabilityDetailsProps) {
  const { availability } = props;

  if (availability.length === 0) {
    return <StyledParagraph>Unknown availability</StyledParagraph>
  }

  if (availability[0].startTime === '00:00' && availability[0].endTime === '23:59') {
    if (availability[0].isAvailable) {
      return (
        <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
          <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='icon-available' />
          <StyledParagraph bold style={{ color: Colors.primary500 }}>Available all day</StyledParagraph>
        </View>
      );
    } else {
      return (
        <View style={{ gap: '8px' }}>
          <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
            <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='icon-unavailable' />
            <StyledParagraph bold style={{ color: Colors.error500 }}>Unavailable all day</StyledParagraph>
          </View>

          <StyledParagraph style={{ fontSize: '14px', color: Colors.neutral700 }}><span style={{ fontWeight: 700 }}>Reason:</span> {availability[0].reason}</StyledParagraph>
        </View>
      );
    }
  }

  return (
    <View style={{ gap: '8px' }}>
      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
        <Icon icon={Icons.CircleMinus} size='medium' style={{ color: Colors.warning500 }} />
        <StyledParagraph bold style={{ color: Colors.warning500 }}>Partially available</StyledParagraph>
      </View>
      <View style={{ borderLeft: `3px solid ${Colors.neutral300}`, gap: '16px', marginLeft: '10px', paddingLeft: '16px' }}>
        {availability.map((a, index) => {
          const startTime = new Date();
          startTime.setHours(parseInt(a.startTime.split(':')[0]), parseInt(a.startTime.split(':')[1]), 0, 0);

          const endTime = new Date();
          if (a.endTime === '23:59') {
            endTime.setHours(0, 0, 0, 0);
          } else {
            endTime.setHours(parseInt(a.endTime.split(':')[0]), parseInt(a.endTime.split(':')[1]), 0, 0);
          }

          if (a.isAvailable) {
            return (
              <StyledParagraph key={index}><span style={{ color: Colors.primary500 }}>Available</span> {startTime.toLocaleTimeString('en-US', timeFormatOptions)} to {endTime.toLocaleTimeString('en-US', timeFormatOptions)}</StyledParagraph>
            );
          } else {
            return (
              <View key={index}>
                <StyledParagraph><span style={{ color: Colors.error500 }}>Unavailable</span> {startTime.toLocaleTimeString('en-US', timeFormatOptions)} to {endTime.toLocaleTimeString('en-US', timeFormatOptions)}</StyledParagraph>
                <StyledParagraph style={{ fontSize: '14px', color: Colors.neutral700 }}><span style={{ fontWeight: 700 }}>Reason:</span> {a.reason}</StyledParagraph>
              </View>
            );
          }
        })}
      </View>
    </View>
  );
}