import { Button, Colors, FormModal, Icon, Icons, Modal, ModalBody, ModalFooter, ModalHeader, ModalLauncher, StyledCaption, StyledHeading, StyledParagraph, TextArea, Tooltip, View } from "@barscience/global-components";
import { TimeOffRequest } from "./TimeOffApproval";
import { formatTime, getDayLabel } from "./utils";
import { gql, useMutation } from "@apollo/client";

/* Approve Request Mutation */
const APPROVE_REQUEST = gql`
mutation approveTimeOffRequest($groupId: ID!, $dayIDs: [ID!]!, $comments: String) {
  approveTimeOffRequest(groupID: $groupId, dayIDs: $dayIDs, comments: $comments) {
    id
    type {
      id
      name
      requireApproval
      isRequestToWork
    }
    created
    comments
    user {
      id
      firstName
      lastName
    }
    days {
      id
      date
      startTime
      endTime
      status
      reviewed {
        timestamp
        comments
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

/* Decline Request Mutation */
const DECLINE_REQUEST = gql`
mutation declineTimeOffRequest($groupId: ID!, $dayIDs: [ID!]!, $comments: String!) {
  declineTimeOffRequest(groupID: $groupId, dayIDs: $dayIDs, comments: $comments) {
    id
    type {
      id
      name
      requireApproval
      isRequestToWork
    }
    created
    comments
    user {
      id
      firstName
      lastName
    }
    days {
      id
      date
      startTime
      endTime
      status
      reviewed {
        timestamp
        comments
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

type ReviewDayInput = {
  id: string;
  comments: string;
}

type ReviewAllDaysInput = {
  comments: string;
}

type ReviewRequestModalProps = {
  handleClose?: () => void
  request: TimeOffRequest;
}

export default function ReviewRequestModal(props: ReviewRequestModalProps) {
  const [approveRequest] = useMutation(APPROVE_REQUEST);
  const [declineRequest] = useMutation(DECLINE_REQUEST);

  /* Approve Request */
  const handleApproveDay = async (values: ReviewDayInput) => {
    await approveRequest({
      variables: {
        groupId: props.request.id,
        dayIDs: [values.id],
        comments: values.comments ? values.comments : null,
      }
    });
  }

  const approveDayModal = (
    <FormModal<ReviewDayInput> title='Approve Day' initialValues={{ id: '', comments: '' }} onSubmit={handleApproveDay} submitLabel='Approve'>
      <TextArea name='comments' label='Comments' />
    </FormModal>
  );

  const handleApproveAllDays = async (values: ReviewAllDaysInput) => {
    await approveRequest({
      variables: {
        groupId: props.request.id,
        dayIDs: props.request.days.filter((day) => day.status === 'PENDING_APPROVAL').map((day) => day.id),
        comments: values.comments ? values.comments : null,
      }
    });

    props.handleClose?.();
  }

  const approveAllDaysModal = (
    <FormModal<ReviewAllDaysInput> title='Approve All Days' initialValues={{ comments: '' }} onSubmit={handleApproveAllDays} submitLabel='Approve'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>All pending days in this request will be approved.</StyledParagraph>
        <TextArea name='comments' label='Comments' />
      </View>
    </FormModal>
  );

  /* Decline Request */
  const handleDeclineDay = async (values: ReviewDayInput) => {
    await declineRequest({
      variables: {
        groupId: props.request.id,
        dayIDs: [values.id],
        comments: values.comments ? values.comments : null,
      }
    });
  }

  const declineDayModal = (
    <FormModal<ReviewDayInput> title='Decline Day' initialValues={{ id: '', comments: '' }} onSubmit={handleDeclineDay} submitLabel='Decline' destructive>
      <TextArea name='comments' label='Comments' required />
    </FormModal>
  );

  const handleDeclineAllDays = async (values: ReviewAllDaysInput) => {
    await declineRequest({
      variables: {
        groupId: props.request.id,
        dayIDs: props.request.days.filter((day) => day.status === 'PENDING_APPROVAL').map((day) => day.id),
        comments: values.comments ? values.comments : null,
      }
    });

    props.handleClose?.();
  }

  const declineAllDaysModal = (
    <FormModal<ReviewAllDaysInput> title='Decline All Days' initialValues={{ comments: '' }} onSubmit={handleDeclineAllDays} submitLabel='Decline' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>All pending days in this request will be declined.</StyledParagraph>
        <TextArea name='comments' label='Comments' required />
      </View>
    </FormModal>
  )


  const hasUnreviewedDay = props.request.days.some((day) => day.status === 'PENDING_APPROVAL');

  return (
    <Modal
      header={<ModalHeader title={`${props.request.user.firstName} ${props.request.user.lastName}`} subtitle={`${getDayLabel(new Date(props.request.days[0].date).getDay())} ${props.request.days[0].date} ${props.request.days[0].startTime === '00:00' ? '(All Day)' : formatTime(props.request.days[0].startTime)} - ${getDayLabel(new Date(props.request.days[props.request.days.length - 1].date).getDay())} ${props.request.days[props.request.days.length - 1].date} ${props.request.days[props.request.days.length - 1].endTime === '23:59' ? '(All Day)' : formatTime(props.request.days[props.request.days.length - 1].endTime)}`} />}
      body={
        <ModalBody>
          <View style={{ gap: '24px', minHeight: 'fit-content' }}>
            {props.request.comments &&
              <View>
                <StyledParagraph bold>Request Comments:</StyledParagraph>
                <StyledParagraph>{props.request.comments}</StyledParagraph>
              </View>
            }

            <View style={{ gap: '24px', minHeight: 'fit-content' }}>
              {props.request.days.map((day) => {
                return (
                  <View style={{ gap: '4px' }}>
                    <StyledHeading tag='h6'>{getDayLabel(new Date(day.date).getDay())} {day.date}</StyledHeading>
                    {day.status === 'PENDING_APPROVAL' &&
                      <View style={{ flexDirection: 'row', gap: '24px' }}>
                        <ModalLauncher modal={approveDayModal}>
                          {({ openModal }) => (
                            <Button label='Approve' variant='tertiary' role='button' action={() => { openModal({ id: day.id, comments: '' }); }} style={{ height: 'fit-content' }} />
                          )}
                        </ModalLauncher>

                        <ModalLauncher modal={declineDayModal}>
                          {({ openModal }) => (
                            <Button label='Decline' variant='tertiary' role='button' action={() => { openModal({ id: day.id, comments: '' }); }} destructive style={{ height: 'fit-content' }} />
                          )}
                        </ModalLauncher>
                      </View>
                    }

                    {day.status === 'APPROVED' &&
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} />
                        <StyledParagraph><span style={{ color: Colors.primary500, fontWeight: 700 }}>Approved</span> by {day.reviewed?.user ? `${day.reviewed?.user?.firstName} ${day.reviewed?.user?.lastName}` : 'Automatic Approval'} {new Date(day.reviewed?.timestamp || '').toLocaleString()}</StyledParagraph>
                      </View>
                    }

                    {day.status === 'DECLINED' &&
                      <View style={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
                        <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} />
                        <StyledParagraph bold style={{ color: Colors.error500 }}>Declined</StyledParagraph>
                      </View>
                    }

                    {day.reviewed?.comments &&
                      <View>
                        <StyledCaption bold>Comments:</StyledCaption>
                        <StyledParagraph>{day.reviewed.comments}</StyledParagraph>
                      </View>
                    }
                  </View>
                );
              })}
            </View>

            <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>Submitted {new Date(props.request.created).toLocaleString()}</StyledParagraph>
          </View>
        </ModalBody>
      }
      footer={
        <ModalFooter>
          <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
            <Tooltip content='All days have been reviewed' disabled={hasUnreviewedDay}>
              <ModalLauncher modal={approveAllDaysModal}>
                {({ openModal }) => (
                  <Button label='Approve All' variant='primary' role='button' action={openModal} disabled={!hasUnreviewedDay} />
                )}
              </ModalLauncher>
            </Tooltip>

            <Tooltip content='All days have been reviewed' disabled={hasUnreviewedDay}>
              <ModalLauncher modal={declineAllDaysModal}>
                {({ openModal }) => (
                  <Button label='Decline All' variant='primary' role='button' action={openModal} destructive disabled={!hasUnreviewedDay} />
                )}
              </ModalLauncher>
            </Tooltip>
          </View>
        </ModalFooter>
      }
      onClose={props.handleClose}
    />
  );
}