import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Choice, ErrorPage, Form, HasProductRole, Icons, InfoModal, Link, ModalLauncher, MultiSelect, NoPermission, Products, SchedulingRoles, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, useAuthState, useForm, View } from "@barscience/global-components";
import { useSearchParams } from "react-router-dom";
import useOrgSettings from "../../components/config/useOrgSettings";

/* Get Staff Query */
export const GET_ALL_STAFF = gql`
query getAllScheduleUsers($schedules: [ID!], $includeViewOnly: Boolean!) {
  scheduleUsers(schedules: $schedules) {
    id
    firstName
    lastName
    email
    phone
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
    }
  }
}
`;

export type GetAllStaffResponse = {
  scheduleUsers: ScheduleUser[];
}

type ScheduleUser = {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  schedules: {
    schedule: {
      id: string;
      name: string;
    };
  }[];
}

/* Get Schedules Query */
const GET_ALL_SCHEDULES = gql`
query getSchedulesForStaffFilter($isArchived: Boolean!) {
  schedules(isArchived: $isArchived) {
    id
    name
  }
}
`;

type GetAllSchedulesResponse = {
  schedules: Schedule[];
}

type Schedule = {
  id: string;
  name: string;
}

/* Page Forms */
type SearchFormInput = {
  name: string;
}

const ALLOWED_ROLES = [SchedulingRoles.ShiftLeader, SchedulingRoles.Manager, SchedulingRoles.Admin];

export default function StaffList() {
  const { state } = useAuthState();
  const { settings } = useOrgSettings();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { data: staffData, loading: staffIsLoading, error: staffError } = useQuery<GetAllStaffResponse>(GET_ALL_STAFF, {
    variables: {
      schedules: searchParams.get('schedules') ? searchParams.get('schedules')?.split(',') : null,
      includeViewOnly: false,
    },
  });
  const { data: schedulesData, loading: schedulesAreLoading, error: schedulesError } = useQuery<GetAllSchedulesResponse>(GET_ALL_SCHEDULES, {
    variables: {
      isArchived: false
    },
  })

  const handleSearch = (values: SearchFormInput) => {
    let firstName = values.name.substring(0, values.name.lastIndexOf(' '));
    let lastName = values.name.substring(values.name.lastIndexOf(' ') + 1);

    if (firstName === '') {
      firstName = lastName;
    }

    if (lastName === '') {
      lastName = firstName;
    }

    if (!values.name) {
      searchParams.delete('name');
      setSearchParams(searchParams);
    } else {
      searchParams.set('name', `${firstName}+${lastName}`);
      setSearchParams(searchParams);
    }
  }

  const searchForm = useForm<SearchFormInput>({
    initialValues: {
      name: searchParams.get('name') || '',
    },
    onSubmit: handleSearch
  });

  const handleScheduleChange = (_: string, value: { [name: string]: boolean }) => {
    const selectedSchedules = Object.keys(value).filter((key) => value[key]);

    if (selectedSchedules.length === 0) {
      searchParams.delete('schedules');
      setSearchParams(searchParams);
    } else {
      searchParams.set('schedules', selectedSchedules.join(','));
      setSearchParams(searchParams);
    }
  }

  const getSelectedSchedules = () => {
    const selectedSchedules: { [name: string]: boolean } = {};
    schedulesData?.schedules?.forEach((s) => {
      selectedSchedules[s.id] = false;
    })

    const schedules = searchParams.get('schedules');
    if (schedules) {
      schedules.split(',').forEach((s) => {
        selectedSchedules[s] = true;
      });
    }

    return selectedSchedules;
  }

  const addEmployeeModal = (
    <InfoModal title='Add an employee'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Only employees with access to the Scheduling application are shown in this list.</StyledParagraph>

        {state.user?.roles[Products.Org] ?
          <StyledParagraph bold>To add a new or existing employee, you'll need to add them to the Scheduling application in <Link href='https://org.barscience.us/employees' target='_blank'>Org Admin</Link>.</StyledParagraph>
          :
          <StyledParagraph bold>You don't have permission to create employees or change product access, so to add a new or existing employee, you'll need to contact your organization's administrator. They can then add the employee in the Org Admin application.</StyledParagraph>
        }

        <StyledParagraph>For more information, please see <Link href='https://support.barscience.us/help-center/articles/acaf6355-0e7c-46cb-95b6-89e56c0e2816' target='_blank'>this article</Link>.</StyledParagraph>
      </View>
    </InfoModal>
  );

  let staff = staffData?.scheduleUsers || [];
  if (searchParams.get('name')) {
    const parts = searchParams.get('name')?.split('+') || [''];
    const firstName = parts[0].toLocaleLowerCase();
    let lastName = '';

    if (parts.length > 1) {
      lastName = parts[1].toLocaleLowerCase();
    }

    staff = staff.filter((s) => s.firstName.toLocaleLowerCase().includes(firstName) || s.lastName.toLocaleLowerCase().includes(lastName));
  }

  if (schedulesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!settings.showStaffContactInfo && (!state.user?.roles[Products.Scheduling] || !ALLOWED_ROLES.includes(state.user.roles[Products.Scheduling]))) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (staffError || schedulesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Staff List</StyledHeading>

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin, SchedulingRoles.Manager]}>
            <ModalLauncher modal={addEmployeeModal}>
              {({ openModal }) => (
                <Button label='Not seeing an employee?' variant='tertiary' role='button' action={openModal} style={{ maxWidth: 'fit-content' }} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <Form handleSubmit={searchForm.handleSubmit}>
            <TextField name='name' icon={Icons.MagnifyingGlass} placeholder='Search by name' value={searchForm.values.name.replace('+', '')} error={searchForm.errors.name} onChange={(name: string, value: string) => { searchForm.handleChange(name, value); handleSearch({ name: value }); }} style={{ width: '500px' }} />
          </Form>

          <MultiSelect label='' name='schedules' value={getSelectedSchedules()} onChange={handleScheduleChange} placeholder='Filter by schedule' entityLabel='schedules' style={{ maxWidth: '350px' }}>
            {schedulesData?.schedules?.map((schedule) => (
              <Choice label={schedule.name} value={schedule.id} key={schedule.id} />
            ))}
          </MultiSelect>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card>
          {staffIsLoading ?

            <View>
              <AtomSpinner size='medium' />
            </View>
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Phone</TableHeaderCell>
                  <TableHeaderCell>Schedules</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {staff.map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        {state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin || state.user?.roles[Products.Scheduling] === SchedulingRoles.Manager ?
                          <Link href={`/staff/${user.id}`}>{user.firstName} {user.lastName}</Link>
                          :
                          <>{user.firstName} {user.lastName}</>
                        }
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>{user.schedules.length > 0 ? user.schedules.map((s) => s.schedule.name).join(', ') : '----'}</TableCell>
                    </TableRow>
                  );
                })}
                {staff.length === 0 &&
                  <TableRow>
                    <TableCell>No staff found.</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}