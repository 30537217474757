import { Card, Cell, CircularSpinner, Colors, StyledHeading, View } from "@barscience/global-components";
import { TimeOffRequest } from "./TimeOffApproval";
import UserCalendarRow from "./UserCalendarRow";
import { getCalendarDateLabel } from "./utils";

type CalendarViewProps = {
  requests: TimeOffRequest[];
  requestsAreLoading: boolean;
  selectedTypes: string[];
  startDate: string;
  endDate: string;
}

export default function CalendarView(props: CalendarViewProps) {
  if (props.requestsAreLoading) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
          <CircularSpinner size='large' />
        </View>
      </Cell>
    );
  }

  const requestsByEmployee: { [key: string]: TimeOffRequest[] } = {};
  props.requests.forEach((request) => {
    if (!requestsByEmployee[request.user.id]) {
      requestsByEmployee[request.user.id] = [];
    }

    requestsByEmployee[request.user.id].push(request);
  });

  return (
    <Cell lg={12} md={8} sm={4}>
      <Card size='medium' style={{ maxHeight: '80vh', minHeight: 'max(400px, 40vh)', overflow: 'auto', padding: 0 }}>
        <View>
          <View style={{ borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', minWidth: 'fit-content' }}>
            <View style={{ alignItems: 'center', backgroundColor: '#ffffff', borderRight: `1px solid ${Colors.neutral300}`, boxSizing: 'border-box', justifyContent: 'center', left: 0, maxWidth: '250px', minWidth: '250px', padding: '16px', position: 'sticky', zIndex: 1 }}>
              <StyledHeading tag='h6' style={{ fontSize: '16px', textAlign: 'center' }}>Employee</StyledHeading>
            </View>

            {[0, 1, 2, 3, 4, 5, 6].map((day) => (
              <View key={day} style={{ alignItems: 'center', borderRight: `1px solid ${Colors.neutral300}`, boxSizing: 'border-box', flexGrow: 1, justifyContent: 'center', minWidth: '150px', padding: '16px', width: '80px' }}>
                <StyledHeading tag='h6' style={{ fontSize: '16px', textAlign: 'center' }}>{getCalendarDateLabel(props.startDate, day)}</StyledHeading>
              </View>
            ))}
          </View>

          {Object.keys(requestsByEmployee).sort((userIdA, userIdB) => { return requestsByEmployee[userIdA][0].user.firstName.localeCompare(requestsByEmployee[userIdB][0].user.firstName) }).map((userKey) => {
            return (
              <UserCalendarRow requests={requestsByEmployee[userKey]} key={userKey} startDate={new Date(props.startDate)} endDate={new Date(props.endDate)} visibleTypes={props.selectedTypes} />
            );
          })}

          {props.requests.length === 0 && <View style={{ alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}><StyledHeading tag='h6' style={{ color: Colors.neutral700, textAlign: 'center' }}>No requests for this week.</StyledHeading></View>}
        </View>
      </Card>
    </Cell>
  );
}