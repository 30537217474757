import { CSSProperties } from "aphrodite";
import { TimeOffRequest, TimeOffRequestDay } from "./TimeOffApproval";
import { Colors, ModalLauncher, StyledParagraph, Tooltip, View } from "@barscience/global-components";
import ReviewRequestModal from "./ReviewRequestModal";
import { formatTime } from "./utils";

type UserRowProps = {
  requests: TimeOffRequest[];
  startDate: Date;
  endDate: Date;
  visibleTypes: string[];
}

export default function UserCalendarRow(props: UserRowProps) {
  type RequestInfo = {
    request: TimeOffRequest;
    day: TimeOffRequestDay;
    dayIndex: number;
  }

  const getRequestForDate = (date: Date): RequestInfo | null => {
    for (let request of props.requests) {
      if (props.visibleTypes.length > 0 && !props.visibleTypes.some((visibleType) => visibleType === request.type.id)) {
        continue;
      }

      if (request.type.isRequestToWork) {
        continue;
      }

      for (let i = 0; i < request.days.length; i++) {
        const requestDate = new Date(request.days[i].date);
        if (requestDate.getDate() === date.getDate() && requestDate.getMonth() === date.getMonth() && requestDate.getFullYear() === date.getFullYear()) {
          return {
            request: request,
            day: request.days[i],
            dayIndex: i,
          };
        }
      }
    }

    return null;
  }

  const getRequestInfoStyle = (request: RequestInfo) => {
    if (!request) {
      return {};
    }

    let styles: CSSProperties = {};

    if (request.day.status === 'PENDING_APPROVAL') {
      styles.backgroundColor = Colors.warning200;
      styles.borderRight = `1px solid ${Colors.warning200}`;
    } else if (request.day.status === 'APPROVED') {
      styles.backgroundColor = Colors.primary200;
      styles.borderRight = `1px solid ${Colors.primary200}`;
    } else if (request.day.status === 'DECLINED') {
      styles.backgroundColor = Colors.error200;
      styles.borderRight = `1px solid ${Colors.error200}`;
    }

    return styles;
  }

  if (props.requests.length === 0) {
    return <></>;
  }

  return (
    <View style={{ borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', minWidth: 'fit-content' }}>
      <View style={{ backgroundColor: '#ffffff', borderRight: `1px solid ${Colors.neutral300}`, boxSizing: 'border-box', left: 0, maxWidth: '250px', minWidth: '250px', padding: '16px', position: 'sticky', zIndex: 1 }}>
        <StyledParagraph>{props.requests[0].user.firstName} {props.requests[0].user.lastName}</StyledParagraph>
      </View>

      {[0, 1, 2, 3, 4, 5, 6].map((dayOffset) => {
        const requestDate = new Date(props.startDate);
        requestDate.setDate(requestDate.getDate() + dayOffset);
        const request = getRequestForDate(requestDate);

        if (request) {
          return (
            <Tooltip referenceContainerStyle={{ display: 'flex', flexGrow: 1, height: 'none' }} key={dayOffset} content={
              <View style={{ gap: '16px' }}>
                <View>
                  <StyledParagraph bold>{request.request.days[0].date} {request.request.days[0].startTime === '00:00' ? '(All Day)' : formatTime(request.request.days[0].startTime)} - {request.request.days[request.request.days.length - 1].date} {request.request.days[request.request.days.length - 1].endTime === '23:59' ? '(All Day)' : formatTime(request.request.days[request.request.days.length - 1].endTime)}</StyledParagraph>
                  <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>Submitted {new Date(request.request.created).toLocaleString()}</StyledParagraph>
                </View>

                <View>
                  <StyledParagraph bold>Type:</StyledParagraph>
                  <StyledParagraph>{request.request.type.name} {request.request.type.isRequestToWork && <span style={{ color: Colors.primary500 }}>(Request to work)</span>}</StyledParagraph>
                </View>

                <View>
                  <StyledParagraph bold>Comments:</StyledParagraph>
                  <StyledParagraph>{request.request.comments ? request.request.comments : '-----'}</StyledParagraph>
                </View>
              </View>
            }>
              <ModalLauncher modal={<ReviewRequestModal request={request.request} />}>
                {({ openModal }) => (
                  <View style={{ alignItems: 'center', boxSizing: 'border-box', cursor: 'pointer', flexGrow: 1, justifyContent: 'center', minWidth: '150px', padding: '16px', width: '80px', ...(getRequestInfoStyle(request)) }} onClick={openModal}>
                    {(request.dayIndex === 0 || (request.dayIndex > 0 && request.request.days[request.dayIndex - 1].status !== 'PENDING_APPROVAL')) && request.day.status === 'PENDING_APPROVAL' && <StyledParagraph style={{ position: 'absolute', color: Colors.warning500 }} bold>Pending approval</StyledParagraph>}
                    {(request.dayIndex === 0 || (request.dayIndex > 0 && request.request.days[request.dayIndex - 1].status !== 'APPROVED')) && request.day.status === 'APPROVED' && <StyledParagraph style={{ position: 'absolute', color: Colors.primary500 }} bold>Approved</StyledParagraph>}
                    {(request.dayIndex === 0 || (request.dayIndex > 0 && request.request.days[request.dayIndex - 1].status !== 'DECLINED')) && request.day.status === 'DECLINED' && <StyledParagraph style={{ position: 'absolute', color: Colors.error500 }} bold>Declined</StyledParagraph>}
                  </View>
                )}
              </ModalLauncher>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip referenceContainerStyle={{ display: 'flex', flexGrow: 1, height: 'none' }} key={dayOffset} content={null} disabled>
              <View key={dayOffset} style={{ alignItems: 'center', backgroundColor: Colors.neutral50, borderRight: `1px solid ${Colors.neutral300}`, boxSizing: 'border-box', flexGrow: 1, justifyContent: 'center', minWidth: '150px', padding: '16px', width: '80px' }}>

              </View>
            </Tooltip>
          );
        }
      })}
    </View>
  );
}