/**
 * Changes the format of a time string from 23:59 to 11:59 PM
 */
export const formatTime = (time: string): string => {
  let [hour, minute] = time.split(':');
  let dayPart = 'AM';

  if (parseInt(hour) > 11) {
    hour = (parseInt(hour) - 12).toString();
    dayPart = 'PM';
  }

  return `${parseInt(hour)}:${minute} ${dayPart}`;
}

/**
 * Given the day index of the week, returns the short day label.
 * The week starts with Sunday as index 0.
 */
export const getDayLabel = (day: number) => {
  switch (day) {
    case 0:
      return 'Sun.';
    case 1:
      return 'Mon.';
    case 2:
      return 'Tue.';
    case 3:
      return 'Wed.';
    case 4:
      return 'Thu.';
    case 5:
      return 'Fri.';
    case 6:
      return 'Sat.';
  }
}

/**
 * Returns the date label for a calendar day. The label includes the day of week and full date.
 */
export const getCalendarDateLabel = (startDateString: string, offset: number) => {
  const startDate = new Date(startDateString);
  startDate.setHours(0, 0, 0, 0);
  startDate.setDate(startDate.getDate() + offset);

  const dateString = startDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return <>{`${getDayLabel(startDate.getDay())}`}<br />{`${dateString}`}</>;
}